const en = {
    msgAppName: "Woodcircle",

    actionGetStart: "Get started",
    actionDetails: "Details",
    actionLogin: "Sign in",
    actionRegister: "Sign up",
    actionLogout: "Logout",
    actionSend: "Send",
    actionGoTest: "Go to test",
    actionSeeCert: "See certificated",
    actionNextPill: "Go to next lesson",
    actionNewThread: "New thread",
    actionBack: "Go back",
    actionComment: "Comment",
    actionUpdate: "Update",
    actionChangePass: "Change password",
    actionAddAnnouncement: "Add announcement",
    actionStartTest: "Start test",
    actionSubmit: "Submit",
    actionClose: "Close",
    actionEdit: "Edit",
    actionDelete: "Delete",
    actionShow: "Show",
    actionNext: "Next",
    actionDone: "Done",
    actionOpenResource: "Open resource",
    actionDownloadInfo: "Download the user manual",
    actionDownloadScript: "Download the script",
    actionDownloadPresentation:"Download the presentation",
    actionCourseCertificate: "Course certificate",
    actionRequestCode: "Request code",

    msgWelcome: "Welcome",
    msgBackToUnit: "Back to learning unit",
    msgExploreUnits: "Explore the WOODCircle on-line training course",
    msgExploreUnitsText: "The twin transition is changing the internal processes of the Wood and Furniture companies through two strong trends that can make our companies more attractive for youngers: digitalization and circular economy. WOODCircle aims to cope with this transition, by focusing on the creation of a new occupational profile: the Circular and Sustainable Woodworker. This online training course provides learners with both technical and environmental skills and competences related to Circular Economy and Sustainability, enabling them to respond to the needs of the labour-market. WOODCircle is the twin project of the previous Erasmus+ Project <a style='color:white; cursor: pointer;' target='blank' href='https://www.woodigital.eu'>WOODigital</a>, managed by the same consortium and aiming at addressing the “digital” perspective of the twin transition by providing digital skills and competencies to allow learners to become competent WOODWORKERS 4.0.",
    msgLearningUnits: "Learning Units",
    msgLatestPills: "Recommended lessons",
    msgLatestPillsText: "Discover lessons recommended by our European Partners. ",

    msgPills: "Lessons",
    msgPartners: "Partners",
    msgNextLearning: "Next learning units",

    msgExamAlert: "On completion of all the lessons in the learning unit, students must complete a <b>multiple-choice self-assessment</b> in order to obtain a completion <b>certificate and digital badge.</b> A minimum <b>result of 75%</b> is required to pass the Learning Unit.",
    msgAssignment: "Assignment",
    msgForStudents: "PROJECT ASSIGNMENT LU1-4",
    msgForTeachers: "ASSIGNMENT LU%d",
    msgProgressionOptions: "Progression options",
    msgProgressionOptionsSubtitle: "Links to some additional progression options in Ireland, Spain, Italy, France and Hungary.",
    msgCompetencesUnitTitle: "On completion of the lessons in the learning unit, the students should be able to:",

    msgCompetencesTitle: "On completion of this lesson, the students should be able to:",
    msgTopics: "Topics",
    msgHowApply: "Good practice",
    msgCaseStudy: "Case study",
    msgInterview: "Interview",
    msgResources: "Learning resources",
    msgResourcesSubtitle: "Links to some additional readings and open access materials.",

    msgStartTestSubtitle: "**On completion of all the lessons in the learning unit**",

    msgWooLearningPlatform: "WOODCircle Learning Platform",
    msgWooLearningPlatformText: "The WOODCircle training platform is dedicated to persons and institutions interested in fostering circular economy and sustainability and benefit from a quality training opportunity.<br/><br/>The platform nurtures a generation of young Europeans, students, professionals, but also VET centres, RTOs and companies, and provide them with skills and competences required by the wood and furniture job market undergoing a circular transition process. We strongly recommend this training to:",

    msgStudents: "Students",
    msgEducators: "Educators",
    msgCompanies: "Companies & Associations",
    msgProfessionals: "Professionals / Unemployed",

    msgStudentsText: "Future woodworkers that before entering the labor market wish to acquire necessary green and circular skills and ensure that they have up-to-date knowledge of the wood and furniture industry in Europe.",
    msgEducatorsText: "VET and training centers educating future woodworkers and interested in innovative and qualitative training content.",
    msgCompaniesText: "Wood and furniture companies willing to upskill the green and circular competences of its employees.",
    msgProfessionalsText: "Those looking for opportunities to develop specific professional skills and/or discover new career paths.",

    msgContact: "Contact",
    msgHome: "Home",
    msgForum: "Forum",
    msgProfile: "Profile",

    msgAnnouncements: "Announcements",
    msgAnnouncementsFinished: "Finished Announcements",

    msgThreads: "Threads",
    msgOpen: "Open",
    msgClose: "Close",
    msgLeaveComment: "Leave a comment",

    msgTeacherProfile: "Teacher Profile",
    msgUserProfile: "User Profile",
    msgUserInfo: "User information",

    msgRegisterText: "Register and explore the WOODCircle online training course.",

    msgName: "Name and Surname",
    msgEmail: "Email",
    msgCountry: "Country",
    msgAge: "Age",
    msgGender: "Gender",
    msgOrganization: "Organization",

    msgForgetPass: "Forget your password?",
    msgRecoveryPassword: "Recovery password",
    msgCode: "Code",

    msgPassword: "Password",
    msgPassInfo: "Change your current password",
    msgCurrentPass: "Current password",
    msgNewPassword: "New password",

    msgNewAnnouncement: "New Announcement",
    msgNewAnnouncementInfo: "Add a new announcement",
    msgAnnouncesInfo: "List of my announcements",

    msgMyCertificates: "My certificates",
    msgMyCertificatesInfo: "List of all my certificates",

    msgDatenEnd: "Date end",
    msgText: "Text",
    msgWriteText: "Write your text",

    msgTitle: "Title",

    msgTerms: "Accept our data <a href='https://ambitcluster.org/en/privacy-policy'>privacy policy.</a>",
    msgCopyright: "Copyright&copy;2023 WOODCIRCLE. All rights reserved",

    msgProyectOwner: "A PROJECT OF",
    msgFooterContact: "CONTACT US",
    msgFooterNews: "OUR NEWSLETTER",
    msgFooterLegal: "Legal notice",
    msgFooterPrivacy: "Privacy",
    msgFooterCookies: "Cookie Policy",
    msgFooterCondic: "Condiciones generales del servicio",
    msgFooterCalidad: "Política de calidad",
    msgFooterEu: "The \"WOODcircle\" project is co-funded by the Erasmus+ programme of the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union Commission, who cannot be held responsible for them.",
    msgFooterGr: "Grant Agreement: 2022-1-IE01-KA220-VET-000086833",
    msgCookies: "Esta Web utiliza cookies de terceros de publicidad comportamental propiedad de Google, persistentes durante 2 años, para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. Si continúa navegando consideramos que está de acuerdo con su uso. Podrá revocar el consentimiento y obtener más información consultando nuestra Política de cookies.",
    msgFooterLinkedIn: 'LINKEDIN GROUP',

    msgSendSuccess: "Operation success",

    errorOblData: "Fill all required fields",
    errorLogin: "Email/password incorrect",
    errorSend: "Operation error, please check your information",
    errorEmailFormat: "Email format error",
    errorRecovery: "Recovery password error, please check code provided",
    errorTerms: "You must accept terms and conditions",

    msgNotPass: "You have not passed this test, please re-read the lesson and try again.",
    msgCongratulations: "Congratulations !! You have passed this test. You can find your certificate in Profile's section.",

    /* PROFILE TYPES */
    msgStudent: "Student",
    msgVetProvider: "Teacher - VET",
    msgHightEducation: "Teacher - Higher Education",
    msgCompany: "Company",
    msgCompanyWorker: "Company worker",
    msgCompanyManager: "Company manager",
    msgOther: "Other",

    /* GENDER */
    msgFemale: "Female",
    msgMale: "Male",
    msgNonBinary: "Non-binary",
    msgTransgender: "Transgender",
    msgIntersex: "Intersex",
    msgLetme: "Let me type…",
    msgNotSay: "I prefer not to say",

    /* COUNTRIES */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",

}

export default en;