const it = {
    msgAppName: "Woodcircle",

    actionGetStart: "Inizia",
    actionDetails: "Dettagli",
    actionLogin: "Accedi",
    actionRegister: "Iscriviti",
    actionLogout: "Esci",
    actionSend: "Invia",
    actionGoTest: "Vai al test",
    actionSeeCert: "Visualizza il certificato",
    actionNextPill: "Vai alla prossima lezione",
    actionNewThread: "Nuovo argomento",
    actionBack: "Indietro",
    actionComment: "Commento",
    actionUpdate: "Aggiorna",
    actionChangePass: "Cambia password",
    actionAddAnnouncement: "Aggiungi annuncio",
    actionStartTest: "Inizia il test",
    actionSubmit: "Invia",
    actionClose: "Chiudi",
    actionEdit: "Modifica",
    actionDelete: "Cancella",
    actionShow: "Mostra",
    actionNext: "Avanti",
    actionDone: "Finito",
    actionOpenResource: "Risorsa aperta",
    actionDownloadInfo: "Scarica il manuale utente",
    actionDownloadScript: "Scarica il testo",
    actionDownloadPresentation: "Scarica la presentazione",
    actionCourseCertificate: "Certificato del corso",
    actionRequestCode: "Codice di richiesta",

    msgWelcome: "Benvenuto",
    msgBackToUnit: "Torna all'unità didattica",
    msgExploreUnits: "Esplora il corso di formazione online WOODCircle",
    msgExploreUnitsText: "La duplice transizione sta rivoluzionando i processi manifatturieri del legno-arredo orientandoli verso le due tendenze capaci di rendere le nostre imprese più attrattive per i giovani: la digitalizzazione e l’economia circolare. WOODCircle ha l'obiettivo di fronteggiare questa transizione, focalizzandosi sulla creazione e promozione di un nuovo profilo occupazionale: l'Operatore del Legno esperto in Sostenibilità ed Economia Circolare. Questo corso di formazione online fornisce agli studenti le abilità e le competenze tecniche ed ambientali relative all'Economia Circolare ed alla sostenibilità, rendendoli in grado di soddisfare le necessità del mercato del lavoro. WOODCircle prosegue l’esperienza del precedente Progetto Erasmus+ <a style='color:white; cursor: pointer;' target='blank' href='https://www.woodigital.eu'>WOODigital</a>, promosso dallo stesso partenariato e indirizzato alla prospettiva \"digital\" della duplice transizione, fornendo agli studenti le abilità e le competenze digitali per permettere di diventare Operatori del Legno 4.0",
    msgLearningUnits: "Unità didattica",
    msgLatestPills: "Lezione consigliata",
    msgLatestPillsText: "Scopri le lezioni consigliate dai nostri Partner Europei. ",

    msgPills: "Lezioni",
    msgPartners: "Partners",
    msgNextLearning: "Prossime unità didattiche",

    msgExamAlert: "Al termine del completamento di tutte le lezioni delle unità didattiche, gli studenti dovranno sostenere un  <b>questionario di autovalutazione</b> per ottenere al suo compimento un <b>certificato e un badge digitale.</b> Per superare l'Unità d'apprendimento è richiesto un <b>risultato minimo pari al 75%</b>.",
    msgAssignment: "Esercizio di approfondimento",
    msgForStudents: "ESERCIZIO DI APPROFONDIMENTO UDA1-4",
    msgForTeachers: "ESERCIZIO DI APPROFONDIMENTO UDA%d",
    msgProgressionOptions: "Opzioni di crescita",
    msgProgressionOptionsSubtitle: "Links per conoscere alcune possibilità di crescita in Irlanda, Spagna, Italia, Francia e Ungheria.",
    msgCompetencesUnitTitle: "Al completamento delle lezioni delle unità didattiche, gli studenti saranno in grado di:",

    msgCompetencesTitle: "Al termine del completamento di questa lezione, gli studenti saranno in grado di:",
    msgTopics: "Argomenti",
    msgHowApply: "Buona pratica",
    msgCaseStudy: "Caso studio",
    msgInterview: "Intervista",
    msgResources: "Links di approfondimento",
    msgResourcesSubtitle: "Links relativi alle letture addizionali ed ai materiali ad accesso libero.",

    msgStartTestSubtitle: "**Al completamento di tutte le lezione dell'unità di apprendimento**",

    msgWooLearningPlatform: "Piattaforma d'apprendimento WOODCircle",
    msgWooLearningPlatformText: "La piattaforma d'apprendimento di WOODCircle è dedicata a persone ed istituzioni interessate ai temi dell'economia circolare e della sostenibilità che vogliano beneficiare di un'opportunità di crescita qualitativa. <br/><br/> La piattaforma si rivolge ad una generazione di giovani Europei, studenti, professionisti, ma anche centri VET, RTO e imprese, dotandoli delle competenze e delle abilità richieste dal mercato del lavoro del settore legno-arredo, il quale sta attraversando un processo di transizione circolare. Raccomandiamo fortemente questo corso a:",

    msgStudents: "Studenti",
    msgEducators: "Insegnanti",
    msgCompanies: "Imprese ed Associazioni",
    msgProfessionals: "Professionisti / Disoccupati",

    msgStudentsText: "Gli operatori del legno del futuro che prima di fare il loro ingresso nel mercato del lavoro desiderino acquisire le abilità sostenibili e circolari necessarie ad assicurarsi il possesso di conoscenze sempre aggiornate relative al settore del legno-arredo europeo.",
    msgEducatorsText: "VET e centri di formazione per l'istruzione di futuri operatori del legno interessati a contenuti educativi innovativi e di qualità.",
    msgCompaniesText: "Imprese del legno-arredo intenzionate ad incrementare le competenze sulla sostenibilità e sull' economia circolare dei loro operatori.",
    msgProfessionalsText: "Tutti coloro che sono alla ricerca di opportunità per sviluppare specifiche abilità professionali e/o scoprire nuovi percorsi occupazionali.",

    msgContact: "Contatti",
    msgHome: "Home",
    msgForum: "Forum",
    msgProfile: "Profilo",

    msgAnnouncements: "Annunci",
    msgAnnouncementsFinished: "Annunci finalizzati",

    msgThreads: "Temi",
    msgOpen: "Apri",
    msgClose: "Chiudi",
    msgLeaveComment: "Lascia un commento",

    msgTeacherProfile: "Profilo del docente",
    msgUserProfile: "Profilo dell'utente",
    msgUserInfo: "informazioni sull'utente",

    msgRegisterText: "Registrati ed esplora il corso di formazione online WOODCircle.",

    msgName: "Nome e cognome",
    msgEmail: "Email",
    msgCountry: "Paese",
    msgAge: "Età",
    msgGender: "Genere",
    msgOrganization: "Organizzazione",

    msgForgetPass: "Password dimenticata?",
    msgRecoveryPassword: "Reimposta password",
    msgCode: "Codice",

    msgPassword: "Password",
    msgPassInfo: "Cambia la tua password",
    msgCurrentPass: "Password attuale",
    msgNewPassword: "Nuova password",

    msgNewAnnouncement: "Nuovo annuncio",
    msgNewAnnouncementInfo: "Aggiungi un nuovo annuncio",
    msgAnnouncesInfo: "Lista dei miei annunci",

    msgMyCertificates: "I miei certificati",
    msgMyCertificatesInfo: "Lista dei miei certificati",

    msgDatenEnd: "Data di scadenza",
    msgText: "Testo",
    msgWriteText: "Scrivi il tuo testo",

    msgTitle: "Titolo",

    msgTerms: "Accetta la nostra <a href='https://ambitcluster.org/en/privacy-policy'> informativa sulla privacy.</a>",
    msgCopyright: "Copyright&copy;2023 WOODCIRCLE. Tutti i diritti riservati",

    msgProyectOwner: "UN PROGETTO DI",
    msgFooterContact: "CONTATTACI",
    msgFooterNews: "LA NOSTRA NEWSLETTER",
    msgFooterLegal: "Legal notice",
    msgFooterPrivacy: "Privacy",
    msgFooterCookies: "Informativa sui Cookie",
    msgFooterCondic: "Condizioni generali del servizio",
    msgFooterCalidad: "Politica della qualità",
    msgFooterEu: "Il progetto \"WOODcircle\" è cofinanziato dal programma Erasmus+ dell’Unione Europea. Tuttavia, le opinioni espresse sono solamente quelle degli autori e non necessariamente riflettono quelle della Commissione Europea, che non è per esse responsabile.",
    msgFooterGr: "Accordo di sovvenzione: 2022-1-IE01-KA220-VET-000086833",
    msgCookies: "Questo sito web utilizza cookie di terze parti per la pubblicità comportamentale di proprietà di Google, persistenti per 2 anni, al fine di migliorare i nostri servizi e mostrarti pubblicità correlata alle tue preferenze mediante l'analisi delle tue abitudini di navigazione. Se continui a navigare, consideriamo che accetti il loro utilizzo. Puoi revocare il consenso e ottenere ulteriori informazioni consultando la nostra Informativa sui cookie.",
    msgFooterLinkedIn: 'GRUPPO LINKEDIN',

    msgSendSuccess: "Esito dell'operazione",

    errorOblData: "Compila tutti i campi obbligatori",
    errorLogin: "Email/password errate",
    errorSend: "Errore durante l'operazione, per favore controlla le tue informazioni",
    errorEmailFormat: "Formato email errato",
    errorRecovery: "Errore di ripristino della password, per favore controlla il codice inserito",
    errorTerms: "Devi accettare i termini e le condizioni",

    msgNotPass: "Non hai superato questo test, per favore rileggi la lezione e prova di nuovo.",
    msgCongratulations: "Congratulazioni !! Hai superato questo test. Potrai trovare il tuo certificato nella sezione Profilo.",
    /* PROFILE TYPES */
    msgStudent: "Studente",
    msgVetProvider: "Docente - VET",
    msgHightEducation: "Docente - Insegnamento superiore",
    msgCompany: "Impresa",
    msgCompanyWorker: "Operatore d'impresa",
    msgCompanyManager: "Manager d'impresa",
    msgOther: "Altro",
    /* GENDER */
    msgFemale: "Femmina",
    msgMale: "Maschio",
    msgNonBinary: "Non-binario",
    msgTransgender: "Transgender",
    msgIntersex: "Intersessuale",
    msgLetme: "Lasciami scrivere…",
    msgNotSay: "Preferisco non specificarlo",
    /* COUNTRIES */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default it;