import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserAction, UserContext } from '../components/provider/UserProvider';
import { Card, Container, ContainerFlex, TextLocalized, Button } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { checkValues, verifyEmailFormat } from '../components/util/UserUtil';
import InputField from '../components/InputField';

import Icon from '@mdi/react';
import { mdiBookAlphabet, mdiSchoolOutline, mdiDomain, mdiAccountHardHat } from '@mdi/js';

import name from '../images/name.png'
import home from '../images/home.jpg';

const ForgetView = () => {
    const [formData, setFormData] = useState({ email: "", password: "", code: "" })
    const [requestedCode, setRequestedCode] = useState(false);

    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.email !== "") {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(`${HttpOperations.user}/code`,
                    result => setRequestedCode(true),
                    error => setRequestedCode(true),
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const updatePassword = () => {
        if (checkValues(formData)) {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(`${HttpOperations.user}/reset`,
                    result => {
                        history("/login", { replace: true });
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } });
                    },
                    error => {
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorRecovery" } });
                    },
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <ContainerFlex width="12" justify="center" height="calc(100vh - 64px)" image={home}>
            <ContainerFlex width="12" height="100%" pv="32" justify="center" align="center">
                <ContainerFlex width="12" lg="8" height="100%" justify="center" align="center" >
                    <Card width="12" lg="6" justify="center" shadowOn color="color-white" radius="20px">

                        <ContainerFlex width="12" px="16">
                            <ContainerFlex width="12" align="center" children={<img alt="logo" src={name} height="48px" />} />
                            <ContainerFlex width="12" px="8" align="center" children={<TextLocalized children="msgRecoveryPassword" />} />
                            <Card flex mx="8" radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="8" children={<Icon size="20px" path={mdiBookAlphabet} />} />
                            <Card flex mx="8" radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="8" children={<Icon size="20px" path={mdiSchoolOutline} />} />
                            <Card flex mx="8" radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="8" children={<Icon size="20px" path={mdiDomain} />} />
                            <Card flex mx="8" radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="8" children={<Icon size="20px" path={mdiAccountHardHat} />} />
                        </ContainerFlex>

                        <Container width={"12"} height="1px" color="color-gray" />

                        <Container width="12" px="16">
                            <InputField disabled={requestedCode} inputmv="16" title="msgEmail" name="email" value={formData.email} onChange={onChange} />

                            {
                                requestedCode ?
                                    <Fragment>
                                        <InputField type="text" title="msgCode" name="code" value={formData.code} onChange={onChange} />
                                        <InputField type="password" inputmt="16" title="msgNewPassword" name="password" value={formData.password} onChange={onChange} />

                                        <Button onClick={updatePassword} textAlign="center" px="12" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionUpdate" />} />
                                    </Fragment>
                                    :
                                    <Button onClick={sendRequest} textAlign="center" px="12" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionRequestCode" />} />
                            }

                            <Button onClick={() => history(-1)} textAlign="center" px="12" mv="16" fontWeight="300" color="color-black" hoverColor="color-black-a40" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionBack" />} />

                        </Container>
                    </Card>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ForgetView;