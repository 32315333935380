const hu = {
    msgAppName: "Woodcircle",

    actionGetStart: "Kezdjük",
    actionDetails: "Részletek",
    actionLogin: "Bejelentkezés",
    actionRegister: "Feliratkozás",
    actionLogout: "Kijelentkezés",
    actionSend: "Elküld",
    actionGoTest: "Tovább a tesztre",
    actionSeeCert: "Tanúsítvány megtekintése",
    actionNextPill: "Tovább a következő tananyagra",
    actionNewThread: "Új téma kezdeményezése",
    actionBack: "Vissza",
    actionComment: "Hozzászólás",
    actionUpdate: "Frissít",
    actionChangePass: "Jelszó csere",
    actionAddAnnouncement: "Közlemény hozzáadása",
    actionStartTest: "Teszt indítása",
    actionSubmit: "Elküld",
    actionClose: "Bezár",
    actionEdit: "Szerkeszt",
    actionDelete: "Törlés",
    actionShow: "Mutasd",
    actionNext: "Következő",
    actionDone: "Kész",
    actionOpenResource: "Nyílt forrás",
    actionDownloadInfo: "A felhasználói kézikönyv letöltése",
    actionDownloadScript: "A tananyag letöltése",
    actionDownloadPresentation: "Prezentáció letöltése",
    actionCourseCertificate: "Kurzus tanusítvány",
    actionRequestCode: "Kód kérése",


    msgWelcome: "Üdvözöljük",
    msgBackToUnit: "Vissza a tananyaghoz",
    msgExploreUnits: "Fedezze fel a WOODCircle online kurzust!",
    msgExploreUnitsText: "A kettős átmenet két olyan erős tendencia révén változtatja meg a fa- és bútoripari vállalatok belső folyamatait, amelyek vonzóbbá tehetik az ágazatot a fiatalok számára: ezek a digitalizáció és a körforgásos gazdaság. A WOODCircle célja, hogy megbirkózzon ezzel az átmenettel, egy új foglalkozási profil létrehozására összpontosítva: a körkörös és fenntartható faipari munkavállalóét. Az online tanfolyam a körforgásos gazdasághoz és a fenntarthatósághoz kapcsolódó műszaki és környezetvédelmi készségeket és kompetenciákat nyújt a tanulóknak, lehetővé téve számukra, hogy megfeleljenek a munkaerőpiac igényeinek. A WOODCircle a korábbi Erasmus+ projekt ikerprojektje <a style='color:white; cursor: pointer;' target='blank' href='https://www.woodigital.eu'>WOODigital</a>, amelyet ugyanaz a partnerség irányít, és amelynek célja, hogy az ikerszintű átmenet \"digitális\" perspektívájával foglalkozzon, digitális készségek és kompetenciák nyújtásával, hogy a tanulók kompetens FAIPAROSOK 4.0-vá válhassanak.",
    msgLearningUnits: "Tananyagok",
    msgLatestPills: "Ajánlott tananyagok",
    msgLatestPillsText: "Fedezze fel az európai partnereink által ajánlott tananyagokat! ",


    msgPills: "Fejezetek",
    msgPartners: "Partnerek",
    msgNextLearning: "Következő tananyagfejezet",


    msgExamAlert: "A tananyag összes fejezetének elvégzése után a tanulóknak ki kell tölteniük egy <b>feleletválasztós önértékelést</b> a teljesítés igazolása érdekében <b>tanúsítvány és digitális jelvény.</b> Az elérendő minimum <b>teljesítés 75%</b> ami a tanegység teljesítéséhez szükséges.",
    msgAssignment: "Feladat",
    msgForStudents: "PROJEKT FELADAT LU1-4",
    msgForTeachers: "FELADAT LU%d",
    msgProgressionOptions: "További tananyagok",
    msgProgressionOptionsSubtitle: "Linkek alatt további francia, ír, magyar, olasz és spanyol segédanyagok tekinthetők meg.",
    msgCompetencesUnitTitle: "A tananyag fejezeteinek elvégzése után a tanulóknak képesnek kell lenniük:",


    msgCompetencesTitle: "A tananyag fejezeteinek elvégzése után a tanulóknak képesnek kell lenniük:",
    msgTopics: "Téma",
    msgHowApply: "Jógyakorlat",
    msgCaseStudy: "Esettanulmány",
    msgInterview: "Interjú",
    msgResources: "Tananyagok",
    msgResourcesSubtitle: "Linkek további olvasmányokhoz és szabadon hozzáférhető anyagokhoz.",


    msgStartTestSubtitle: "**A tanegység összes fejezetének befejezésekor**",


    msgWooLearningPlatform: "WOODCircle Oktatási Platform",
    msgWooLearningPlatformText: "A WOODCircle képzési platform a körforgásos gazdaság és a fenntarthatóság előmozdításában érdekelt személyek és intézmények számára nyújt minőségi képzési lehetőséget.<br/><br/>A platform fiatal európaiak, diákok, szakemberek, de szakképzési központok, oktatási intézmények és vállalatok generációját is támogatja, és olyan készségekkel és kompetenciákkal látja el őket, amelyeket a körforgásos átmenet folyamatában lévő fa- és bútoripari munkaerőpiac igényel. Kifejezetten ajánljuk ezt a képzést:",


    msgStudents: "Tanulóknak",
    msgEducators: "Oktatóknak",
    msgCompanies: "Vállalatoknak és szervezeteknek",
    msgProfessionals: "Szakmabelieknek / Munkanélkülieknek",


    msgStudentsText: "Jövőbeli faipari munkavállalóknak/vállalkozóknak, akik a munkaerőpiacra való belépés előtt el kívánják sajátítani a szükséges zöld és körkörös készségeket, és biztosítani kívánják, hogy naprakész ismeretekkel rendelkezzenek az európai fa- és bútoriparról.",
    msgEducatorsText: "Szakképzési és képzési központok oktatóinak, munkavállalóinak, akik a jövő faipari szakembereit oktatják, és érdeklődnek az innovatív és minőségi képzési tartalom iránt.",
    msgCompaniesText: "Faipari és bútoripari vállalatok vezetőinek/munkavállalóinak, akik szeretnék továbbképezni alkalmazottaik zöld és körforgásos kompetenciáit.",
    msgProfessionalsText: "Azoknak, akik konkrét szakmai készségek fejlesztésére és/vagy új karrierlehetőségek felfedezésére keresnek lehetőséget.",


    msgContact: "Elérhetőség",
    msgHome: "Home",
    msgForum: "Fórum",
    msgProfile: "Profil",


    msgAnnouncements: "Közlemények",
    msgAnnouncementsFinished: "Befejezett közlemények",


    msgThreads: "Szálak",
    msgOpen: "Kinyit",
    msgClose: "Bezár",
    msgLeaveComment: "Megjegyzés",


    msgTeacherProfile: "Oktatói profil",
    msgUserProfile: "Felhasználói profil",
    msgUserInfo: "Felhasználói információk",


    msgRegisterText: "Regisztráljon és fedezze fel a WOODCircle online tanfolyamot!",


    msgName: "Keresztnév és vezetéknév",
    msgEmail: "E-mail",
    msgCountry: "Ország",
    msgAge: "Kor",
    msgGender: "Nem",
    msgOrganization: "Szervezet",


    msgForgetPass: "Elfelejtette a jelszavát?",
    msgRecoveryPassword: "Jelszó helyreállítása",
    msgCode: "Kód",


    msgPassword: "Jelszó",
    msgPassInfo: "Jelenlegi jelszó módosítása",
    msgCurrentPass: "Aktuális jelszó",
    msgNewPassword: "Új jelszó",


    msgNewAnnouncement: "Új közlemény",
    msgNewAnnouncementInfo: "Új közlemény hozzáadása",
    msgAnnouncesInfo: "Közleményeim listája",


    msgMyCertificates: "Bizonyítványaim",
    msgMyCertificatesInfo: "Az összes bizonyítványom listája",


    msgDatenEnd: "Dátum vége",
    msgText: "Szöveg",
    msgWriteText: "Ide írja a bejegyzést!",


    msgTitle: "Cím",


    msgTerms: "A adatvédelmi szabályzat <a href='https://ambitcluster.org/en/privacy-policy'>elfogadása.</a>",
    msgCopyright: "Copyright&copy;2023 WOODCIRCLE. All rights reserved",


    msgProyectOwner: "A MI PROJEKTÜNK",
    msgFooterContact: "ÍRJON NEKÜNK!",
    msgFooterNews: "HÍRLEVELÜNK",
    msgFooterLegal: "Jogi nyilatkozat",
    msgFooterPrivacy: "Adatvédelem",
    msgFooterCookies: "Cookie-szabályzat",
    msgFooterCondic: "Általános szolgáltatási feltételek",
    msgFooterCalidad: "Minőségügyi politika",
    msgFooterEu: "A \"WOODcircle\" projektet az Európai Unió Erasmus+ programja társfinanszírozza. A kifejtett nézetek és vélemények azonban kizárólag a szerző(k) sajátjai, és nem feltétlenül tükrözik az Európai Unió Bizottságának nézeteit és véleményét, amely nem tehető felelőssé ezekért.",
    msgFooterGr: "Támogatási megállapodás: 2022-1-IE01-KA220-VET-000086833",
    msgCookies: "Ez a weboldal a Google tulajdonában lévő, 2 évig tartósan fennálló, harmadik féltől származó viselkedésalapú hirdetési cookie-kat használ, hogy a böngészési szokások elemzésével javítsuk szolgáltatásainkat és az Ön preferenciáihoz kapcsolódó hirdetéseket jelenítsünk meg Önnek. Ha folytatja a böngészést, úgy tekintjük, hogy Ön hozzájárul ezek használatához. A hozzájárulását visszavonhatja, és további információkat kaphat a Cookie-szabályzatunkban",
    msgFooterLinkedIn: 'LINKEDIN CSOPORT',


    msgSendSuccess: "Sikeres művelet",


    errorOblData: "Kérjük, töltse ki a kötelező mezőket!",
    errorLogin: "Hibás e-mail cím / jelszó",
    errorSend: "Műveleti hiba, kérjük, ellenőrizze adatait!",
    errorEmailFormat: "Hibás e-mail formátum",
    errorRecovery: "Hibás helyreállítási jelszó, kérjük, ellenőrizze a megadott kódot",
    errorTerms: "El kell fogadnia a feltételeket",


    msgNotPass: "Ez a teszt most nem sikerült, kérjük, olvassa el újra az adott tananyagot, és próbálja meg újra!",
    msgCongratulations: "Gratulálunk!!! Átment ezen a vizsgán. A bizonyítványát a Profil szekcióban találja.",


    /* PROFIL TÍPUSA */
    msgStudent: "Tanuló",
    msgVetProvider: "Tanár/oktató - Középfokú szakképzési intézmény",
    msgHightEducation: "Tanár/oktató - Felsőoktatás",
    msgCompany: "Vállalkozás",
    msgCompanyWorker: "Munkavállaló",
    msgCompanyManager: "Cégvezető",
    msgOther: "Egyéb",


    /* NEM */
    msgFemale: "Nő",
    msgMale: "Férfi",
    msgNonBinary: "Nem bináris",
    msgTransgender: "Transzgender",
    msgIntersex: "Interszexuális",
    msgLetme: "Egyéb, mégpedig...",
    msgNotSay: "Nem szeretném megjelölni",


    /* ORSZÁG */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default hu;