import React from 'react';
import { Card, Container } from './styled/Styled';

const MessageItem = ({item}) => {
    return (
        <Card shadowOn width="12" mv="8" color="color-white" overflowY="hidden">
            <Container px="16" fontSize="14px" fontWeight="300" color="color-gray-light" children={`${item.owner.name} commented`} />
            <Container width="12" height="1px" color="color-gray" />
            <Container px="16" children={item.text} />
        </Card>
    );
};

export default MessageItem;