import { mdiSchoolOutline, mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle, mdiChevronLeft, mdiCalendar } from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment } from 'react';
import MessageItem from '../components/MessageItem';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { Container, ContainerFlex, Card, Button, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { getStorageUser } from '../components/util/UserUtil';

const ThreadDetail = () => {
    const [item, setItem] = useState();

    const [isSending, setIsSending] = useState(false);
    const [formData, setFormData] = useState("");
    const [messages, setMessages] = useState([])
    const languageContext = useContext(LanguageContext);

    let history = useNavigate();
    let params = useParams();

    useEffect(() => {
        getItem()
    }, [params.id])

    const getItem = () => HttpConnection(`${HttpOperations.threads}/${params.id}`, result => {
        setItem(result.item)
        setMessages([...result.item.messages]);
    }, error => console.log(error), HttpMethod.get);

    const pushMessage = () => {
        if (!isSending) {
            setIsSending(true)
            HttpConnection(`${HttpOperations.threads}/${params.id}/message`,
                result => {
                    getItem();
                    setFormData("");
                    setIsSending(false);
                },
                error => console.log(error), HttpMethod.post, { text: formData });
        }
    }

    const closeThread = () => HttpConnection(`${HttpOperations.threads}/${params.id}`, result => getItem(),
        error => console.log(error), HttpMethod.put, { title: item.title, text: item.text, thread_state_id: 2 });

    return (
        <Container width="12" justify="center" minheight="100vh" color="color-blue-light-a20">
            <ContainerFlex width="12" px="16" justify="center">
                <ContainerFlex width="12" lg="8" pv="8">
                    <Button onClick={() => history(-1)} flex align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" ph="16" pv="8" radius="10px">
                        <Icon path={mdiChevronLeft} size={1} />
                        <ContainerFlex cursor="pointer" ph="8" fontSize="18px" fontWeight="300" justify="center" align="center" textTransform="uppercase" children={<TextLocalized children="actionBack" />} />
                    </Button>

                    {
                        item ?
                            <Fragment>
                                <Card shadowOn width="12" mv="32" color="color-white" overflowY="hidden">
                                    <ContainerFlex width="12" px="16" align="center" color="color-gray-light" >
                                        <Container width="wrap" fontColor="color-blue-dark" fontWeight="300" fontSize="38px" children={item ? item.title : ""} />

                                        {
                                            getStorageUser().id === item.owner.id && item.state.id === 1 ?
                                                <ContainerFlex display="none">
                                                    <Button onClick={closeThread} mx="8" pv="8" ph="16" fontColor="color-white" fontSize="12px" fontWeight="300" color="color-red" hoverColor="color-red-dark" textTransform="uppercase" children={<TextLocalized children="actionClose" />} />
                                                </ContainerFlex>
                                                : null
                                        }
                                    </ContainerFlex>
                                    <Container width={12} height="1px" color="color-gray" />

                                    <Container width="12" px="16" fontWeight="100" children={item.text} />

                                    <ContainerFlex align="center" ph="16" pb="16" fontColor="color-white">
                                        <Card flex pv="4" ph="8" color="color-black" fontSize="12px" children={<TextLocalized children={item.state.id === 1 ? "msgOpen" : "msgClose"} />} />
                                        <Card flex mh="8" align="center" color="color-black" ph="8" pv="4">
                                            {
                                                item.owner.role.id === 3
                                                    ? <Icon style={{ marginRight: "8px" }} path={mdiSchoolOutline} size={"14px"} />
                                                    : null
                                            }
                                            <Container fontSize="10px" fontWeight="100" children={item.owner.name} />
                                        </Card>

                                        <Card flex ph="8" pv="4" align="center" color="color-black" fontSize="10px" fontWeight="100">
                                            <Icon style={{ marginRight: "8px" }} path={mdiCalendar} size={"14px"} />
                                            <Container children={format(new Date(item.date_create), "dd/MM/yyyy")} />
                                        </Card>
                                    </ContainerFlex>

                                </Card>


                                <Card width="12" height="5px" color="color-black" />
                                <ContainerFlex width="12" pv="16" children={messages.map((item, index) => <MessageItem key={index} item={item} />)} />
                                {
                                    item.state.id === 1 ?
                                        <Fragment>
                                            <Card width="12" height="5px" color="color-black" />

                                            <Card width="12" mt="24" border="solid" color="color-white" borderColor="color-blue-dark" fontColor="color-blue-dark" borderWidth="1px">
                                                <textarea onChange={e => setFormData(e.target.value)} value={formData} placeholder={languageContext.dictionary["msgLeaveComment"]} rows="5" style={{ padding: "16px" }} />
                                            </Card>

                                            <ContainerFlex width="12" justify="flex-end" pb="16">
                                                <Button onClick={pushMessage} pv="8" ph="64" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionComment" />} />
                                            </ContainerFlex>
                                        </Fragment>
                                        : null
                                }
                            </Fragment>
                            : null
                    }
                </ContainerFlex>
            </ContainerFlex>
        </Container>
    );
};

export default ThreadDetail;