import { Fragment, useContext, useEffect } from 'react';
import { LanguageProvider } from './components/provider/LanguageProvider';
import { UserContext, UserProvider } from './components/provider/UserProvider';
import { NotificationView } from './components/util/NotificationUtil';

import './css/normalize.css';
import './css/bootstrap-grid.css';
import './App.css';

import { BrowserRouter as Router, Route, useNavigate, Routes } from 'react-router-dom';
import { Container } from './components/styled/Styled';
import Header from './components/Header';
import Home from './pages/Home';
import { Footer } from './components/Footer';
import LearningUnitDetail from './pages/LearningUnitDetail';
import PillDetail from './pages/PillDetail';
import Forum from './pages/Forum';
import ThreadDetail from './pages/ThreadDetail';
import Profile from './pages/Profile';
import LearningUnitTest from './pages/LearningUnitTest';
import Login from './pages/Login';
import ModalView from './components/util/ModalUtil';
import Register from './pages/Register';
import LearningUnitList from './pages/LearningUnitList';
import ForgetView from './pages/ForgetView';

const ScrollTop = ({ children }) => {
  const history = useNavigate()

  useEffect(() => {
    const unlisten = () => window.scrollTo(0, 0);
    return () => unlisten();
  }, [history]);

  return children;
}

const RouterView = () => {
  const { state } = useContext(UserContext);

  return <ScrollTop>
    {
      state.login ?
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/units" element={<LearningUnitList />} />
          <Route exact path="/unit/:id" element={<LearningUnitDetail />} />
          <Route exact path="/unit/:id/test" element={<LearningUnitTest />} />

          <Route exact path="/pill/:id" element={<PillDetail />} />
          <Route exact path="/forum" element={<Forum />} />
          <Route exact path="/forum/:id" element={<ThreadDetail />} />
          <Route exact path="/profile" element={<Profile />} />
        </Routes>
        : <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/forget" element={<ForgetView />} />

          <Route exact path="*" element={<Login />} />
        </Routes>
    }
  </ScrollTop>
}


const RootView = () => {

  const { state } = useContext(UserContext);
  return <Fragment>
    <Container width="12">
      <Router basename="/">
        <Header />
        <Container>
          <RouterView />
          <Footer />
        </Container>
      </Router>
    </Container>
    {state.notification.show ? <NotificationView /> : null}
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <LanguageProvider children={<UserProvider children={<RootView />} />} />;

export default App;
