import { mdiChevronLeft } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { UserAction, UserContext } from '../components/provider/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations, imageURL } from '../components/util/HttpConnection';

const SingleAnswer = ({ item, updateAnswer }) => {

    const onChange = (e) => updateAnswer(parseInt(e.target.value));

    return <Container width="12" px="16" fontColor="color-black">
        {
            item.answers.map(answer => <ContainerFlex key={answer.id} width="12" mv="8" align="center">
                <input type="radio"
                    checked={answer.selected}
                    onChange={onChange}
                    id={answer.id}
                    value={answer.id} name={item.id} />
                <label className='col' htmlFor={answer.id} children={answer.text} />
            </ContainerFlex>)
        }
    </Container>
}

const QuizItem = ({ item, updateQuiz }) => {
    const updateAnswer = (id) => updateQuiz(item.id, id);

    return <Card shadowOn width="12" mv="16" color={"color-white"}>
        <Card px="16" radius="0" radiustl="8px" radiustr="8px" color={"color-gray-light"} width="12" fontSize="16px" fontWeight="300" fontColor="color-black" children={item.text} />
        <Container width={12} height="1px" color="color-gray" />
        <SingleAnswer item={item} updateAnswer={updateAnswer} />
    </Card>
}

const LearningUnitTest = () => {
    const [item, setItem] = useState();
    const [quizs, setQuizs] = useState([]);

    const { dispatch } = useContext(UserContext);
    const { language } = useContext(LanguageContext);
    const history = useNavigate();

    let params = useParams()

    useEffect(() => {
        HttpConnection(`${HttpOperations.units}/${params.id}?lang=${language.id}`, result => {
            setItem(result.item);
        }, error => console.log(error), HttpMethod.get)

        HttpConnection(`${HttpOperations.units}/${params.id}/test?lang=${language.id}`, result => {
            let quizAux = result.item.quizs;
            quizAux.forEach(quiz => quiz.answers.map(answer => answer.selected = false));
            setQuizs(quizAux);
        }, error => console.log(error), HttpMethod.get)
    }, [params.id, language])

    const sendRequest = () => {
        let answerSelected = [];
        //Add answers to array
        quizs.forEach(quiz => quiz.answers.forEach(answer => {
            if (answer.selected)
                answerSelected.push(answer)
        }));

        //Send responses
        HttpConnection(`${HttpOperations.units}/${item.id}/test?lang=${language.id}`, result => {
            if (result.item == true) {
                //Show congratulations
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgCongratulations" } })
            } else {
                //Show read resources again
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "msgNotPass" } })
            }
        }, error => console.log(error), HttpMethod.patch, answerSelected)
    }

    const updateQuiz = (quizId, answerId) => {
        let auxQuiz = [...quizs];
        auxQuiz.forEach(quiz => {
            if (quiz.id === quizId)
                quiz.answers.forEach(answer => {
                    answer.selected = answer.id === answerId
                })
        })
        setQuizs(auxQuiz);
    }

    return (
        <Container width="12" minheight="calc(100vh - 64px)">
            {item ?
                <ContainerFlex width="12" justify="center" color="color-blue-light-a20">
                    <Container width={12} image={imageURL(item.image)}>
                        <ContainerFlex width={12} justify="center" color="color-gray-alpha" >
                            <ContainerFlex width="12" lg={8} mt="32" ph="16" justify="flex-start" >
                                <Button onClick={() => history(`/unit/${item.id}`)} flex align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" ph="16" pv="8" radius="10px">
                                    <Icon path={mdiChevronLeft} size={1} />
                                    <ContainerFlex cursor="pointer" ph="8" fontSize="18px" fontWeight="300" justify="center" align="center" textTransform="uppercase" children={<TextLocalized children="msgBackToUnit" />} />
                                </Button>
                            </ContainerFlex>

                            <Container width="12" lg="8" ph="16" pv="64" fontColor="color-white">
                                <Container width="12" fontWeight="bold" fontSize="17px" children={"Self-assessment"} />
                                <Container width="12" mb="16" fontWeight="bold" fontSize="74px" children={item && item.content ? item.content.title : ""} />
                                <Container width="12" fontWeight="300" fontSize="18px" children={<TextLocalized children="msgExamAlert" />} />
                            </Container>
                        </ContainerFlex>
                    </Container>

                    <ContainerFlex width="12" lg="8" pv="32" justify="center">
                        <ContainerFlex width="12" ph="16" children={quizs.map((item, index) => <QuizItem key={index} item={item} updateQuiz={updateQuiz} />)} />
                    </ContainerFlex>

                    <ContainerFlex width="12" justify="center" pb="48">
                        <Button onClick={sendRequest} flex width="auto" textAlign="center"
                            fontWeight="300" fontSize="18px" mh="16" pv="16" ph="64"
                            color="color-blue" hoverColor="color-blue-dark" fontColor="color-white"
                            textTransform="uppercase"
                            children={<TextLocalized children="actionSubmit" />} />
                    </ContainerFlex>
                </ContainerFlex>
                : null}
        </Container >
    );
};

export default LearningUnitTest;