import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import InputField, { InputSelectField } from '../components/InputField';
import { UserAction, UserContext } from '../components/provider/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';

import name from '../images/name.png'
import home from '../images/home.jpg';
import Icon from '@mdi/react';
import { mdiBookAlphabet, mdiSchoolOutline, mdiDomain, mdiAccountHardHat, mdiCheck } from '@mdi/js';
import { countries, genderOptions, profileOptions } from '../constants/model';
import { LanguageContext } from '../components/provider/LanguageProvider';

const Register = () => {
    const [showTypeGender, setShowTypeGender] = useState(false)
    const [formData, setFormData] = useState({ name: "", country: "", age: "", gender: "", profile: "", email: "", password: "", terms: false })
    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);
    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onChangeSelect = (e) => {
        if (e.target.name === "gender") {
            if (e.target.value === dictionary[genderOptions[5]]) {
                setShowTypeGender(true)
                setFormData({ ...formData, [e.target.name]: "" })
            } else {
                setShowTypeGender(false)
                setFormData({ ...formData, [e.target.name]: e.target.value })
            }
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const sendRequest = () => {
        if (formData.email !== "" && formData.password !== "" && formData.name !== "" &&
            formData.country !== "" && formData.age !== "" && formData.gender !== "" &&
            formData.profile !== "") {
            if (formData.terms) {
                HttpConnection(HttpOperations.user, response => {
                    dispatch({ action: UserAction.login, data: response.item });
                    history("/", { replace: true });
                }, error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }), HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorTerms" } });
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } });
        }
    }

    return (
        <ContainerFlex width="12" justify="center" minheight="calc(100vh - 64px)" image={home}>
            <ContainerFlex width="12" minheight="calc(100vh - 64px)" pv="32" justify="center" align="center">
                <ContainerFlex width="12" lg="8" height="100%" justify="center" align="center" >
                    <Card flex align="center" width="12" lg="8" shadowOn color="color-white" radius="20px">
                        <ContainerFlex width="12" px="16">
                            <ContainerFlex width="12" align="center" children={<img alt="logo" src={name} height="48px" />} />
                            <ContainerFlex width="12" px="8" align="center" children={<TextLocalized children="msgRegisterText" />} />
                            <Card flex mx="8" radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="8" children={<Icon size="20px" path={mdiBookAlphabet} />} />
                            <Card flex mx="8" radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="8" children={<Icon size="20px" path={mdiSchoolOutline} />} />
                            <Card flex mx="8" radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="8" children={<Icon size="20px" path={mdiDomain} />} />
                            <Card flex mx="8" radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="8" children={<Icon size="20px" path={mdiAccountHardHat} />} />
                        </ContainerFlex>

                        <Container width={"12"} height="1px" color="color-gray" />

                        <Container width="12" px="16">
                            <ContainerFlex width="12" mb="16" fontSize="25px" fontWeight="300" align="center" children={<TextLocalized children="msgUserInfo" />} />
                            <InputField title="msgName" name="name" value={formData.name} onChange={onChange} />

                            <ContainerFlex width={12} mv="16">
                                <InputSelectField inputmr={4} title="msgCountry" name="country" options={countries} value={formData.country} onChange={onChangeSelect} />
                                <InputField inputml={4} title="msgAge" type="number" name="age" value={formData.age} onChange={onChange} />
                            </ContainerFlex>

                            <InputSelectField inputmb={16} title="msgGender" name="gender" options={genderOptions} value={formData.gender} onChange={onChangeSelect} />
                            {
                                showTypeGender ?
                                    <InputField inputmb={16} title="msgLetme" type="text" name="gender" value={formData.gender} onChange={onChange} />
                                    : null
                            }
                            <InputSelectField title="msgProfile" name="profile" options={profileOptions} value={formData.profile} onChange={onChangeSelect} />
                            <ContainerFlex width={12} mv="16">
                                <InputField inputmr={4} title="msgEmail" name="email" value={formData.email} onChange={onChange} />
                                <InputField inputml={4} title="msgPassword" type="password" name="password" value={formData.password} onChange={onChange} />
                            </ContainerFlex>

                            <ContainerFlex width={12} mt={32} justify="center" align="center">
                                <Button onClick={() => setFormData({ ...formData, terms: !formData.terms })} flex justify="center" align="center" mr="8" fontColor="color-white" color={formData.terms ? "color-black" : "color-white"} border="solid" borderColor="color-black" borderWidth="1px"
                                    height="24px" idealwidth="24px" children={<Icon size={0.5} path={mdiCheck} />} />
                                <Container children={<TextLocalized children="msgTerms" />} />
                            </ContainerFlex>

                            <Button width="12" mt="50" onClick={sendRequest} textAlign="center" px="12" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" textTransform="uppercase" fontColor="color-white" children={<TextLocalized children="actionRegister" />} />
                        </Container>
                    </Card>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Register;