import React from 'react';
import { Container } from './styled/Styled';

const CompetenceItem = ({item}) => {
    return (
        <Container width="12" pv="8" ph="16" textAlign="center" fontSize="15px" children={`${item.name}`} />            
    );
};

export default CompetenceItem;
