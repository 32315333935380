import React, { useContext, useState } from 'react';
import { LanguageContext } from './provider/LanguageProvider';
import { Card, Container, TextLocalized } from './styled/Styled';

const InputField = ({
    placeholder, direction, width = "wrap", textSize = "10px", title,
    titlem, titlemt, titleml, titlemr, titlemb, titlemh, titlemv,
    titlep, titlept, titlepl, titlepb, titlepr, titleph = "16", titlepv,
    inputm, inputmt, inputml, inputmr, inputmb, inputmh, inputmv,
    inputp, inputpt, inputpl, inputpb, inputpr, inputph, inputpv,
    value, ...rest
}) => {
    const { dictionary } = useContext(LanguageContext);
    const [clicked, setClicked] = useState(false);
    const titleMargins = { mx: titlem, mt: titlemt, ml: titleml, mr: titlemr, mb: titlemb, mh: titlemh, mv: titlemv, };
    const titlePaddings = { px: titlep, pt: titlept, pl: titlepl, pb: titlepb, pr: titlepr, ph: titleph, pv: titlepv, };
    const inputMargins = { mx: inputm, mt: inputmt, ml: inputml, mr: inputmr, mb: inputmb, mh: inputmh, mv: inputmv, };
    const inputPaddings = { px: inputp, pt: inputpt, pl: inputpl, pb: inputpb, pr: inputpr, ph: inputph, pv: inputpv, };

    return <Card width={width}  {...inputMargins} {...inputPaddings}
        flex
        height="58px" align="center"
        radius="12px"
        color={clicked || value !== "" ? "color-white" : "color-gray"}
        border="solid"
        borderColor={clicked || value !== "" ? "color-black" : "color-gray-dark"}
        borderWidth="1px"
        onFocus={() => setClicked(true)} onBlur={() => setClicked(false)}>
        <Container width="12">
            {
                clicked || value !== ""
                    ? <Container width="12" textAlign="left" fontSize={textSize} fontColor={"color-primary"} textTransform="uppercase" {...titleMargins} {...titlePaddings}
                        children={<TextLocalized children={title} />} />
                    : null
            }
            <input style={{ padding: "0 16px" }} type="text" value={value} width="12" placeholder={placeholder ?? dictionary[title]} {...rest} />
        </Container>
    </Card>
}


/*
export const InputSelectField = ({ label, name, value, onChange, options = [1, 2, 3] }) => {
    const { dictionary } = useContext(LanguageContext);

    return <Container width="12" mv="8">
        <Container mb="8" fontColor="color-blue-dark" fontWeight="300" children={<TextLocalized children={label} />} />
        <Card color="color-white" width="12" border="solid" borderColor="color-blue-dark" fontColor="color-blue-dark" borderWidth="1px">
            <select style={{ padding: "11px 16px" }} onChange={onChange} name={name} value={value}>
                <option value="" children={dictionary[label]} />
                {options.map((opt, index) => <option key={index} value={dictionary[opt]} children={dictionary[opt]} />)}
            </select>
        </Card>
    </Container>
}
*/

export const InputSelectField = ({
    placeholder, direction, width = "wrap", textSize = "10px", title,
    titlem, titlemt, titleml, titlemr, titlemb, titlemh, titlemv,
    titlep, titlept, titlepl, titlepb, titlepr, titleph = "16", titlepv,
    inputm, inputmt, inputml, inputmr, inputmb, inputmh, inputmv,
    inputp, inputpt, inputpl, inputpb, inputpr, inputph, inputpv,
    value, options, ...rest
}) => {
    const [clicked, setClicked] = useState(false);
    const titleMargins = { mx: titlem, mt: titlemt, ml: titleml, mr: titlemr, mb: titlemb, mh: titlemh, mv: titlemv, };
    const titlePaddings = { px: titlep, pt: titlept, pl: titlepl, pb: titlepb, pr: titlepr, ph: titleph, pv: titlepv, };
    const inputMargins = { mx: inputm, mt: inputmt, ml: inputml, mr: inputmr, mb: inputmb, mh: inputmh, mv: inputmv, };
    const inputPaddings = { px: inputp, pt: inputpt, pl: inputpl, pb: inputpb, pr: inputpr, ph: inputph, pv: inputpv, };

    const { dictionary } = useContext(LanguageContext)

    return <Card width={width}  {...inputMargins} {...inputPaddings}
        flex
        height="58px" align="center"
        radius="12px"
        color={clicked || value !== "" ? "color-white" : "color-gray"}
        border="solid"
        borderColor={clicked || value !== "" ? "color-black" : "color-gray-dark"}
        borderWidth="1px"
        onFocus={() => setClicked(true)} onBlur={() => setClicked(false)}>
        <Container width="12">
            {
                clicked || value !== ""
                    ? <Container width="12" textAlign="left" fontSize={textSize} fontColor={"color-black"} textTransform="uppercase" {...titleMargins} {...titlePaddings}
                        children={<TextLocalized children={title} />} />
                    : null
            }
            <select style={{ padding: "0px 16px" }} value={value} {...rest}>
                <option value="" children={placeholder ?? dictionary[title]} />
                {options.map((opt, index) => <option key={index} value={dictionary[opt]} children={dictionary[opt]} />)}
            </select>
        </Container>
    </Card>
}

export default InputField;