import React from 'react';
import { Button } from './styled/Styled';

const ResourceItem = ({ item, index }) => {
    return (
        <Button onClick={() => window.open(item.link, "_blank")} flex align="center" radius="15px" mr="16" mb="8"
            border="solid" borderColor="color-black" borderWidth="2px" color="color-black" hoverColor="color-black-a40"
            fontWeight="bold" fontColor="color-white" ph="32" pv="8" textTransform="uppercase" children={item.name} />
        /*
        <Button onClick={() => window.open(item.link, "_blank")} flex multiline={false} width="12" align="flex-start" px="16" >
            <Card minwidth="30px" idealwidth="30px" textAlign="center" border="solid" borderColor="color-white" borderWidth="1px" radius="10px" children={index + 1} />
            <Container ml="16" key={index} children={item.name} />
        </Button>
        */
    );
};

export default ResourceItem;