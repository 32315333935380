import { mdiBullhorn, mdiBullseye, mdiTrophy } from '@mdi/js';
import Icon from '@mdi/react';
import { format } from 'date-fns';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import InputField, { InputSelectField } from '../components/InputField';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { UserAction, UserContext } from '../components/provider/UserProvider';
import { Container, ContainerFlex, Card, Button, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations, serverUrlEndpoint } from '../components/util/HttpConnection';
import { updateStorageUser } from '../components/util/UserUtil';
import { countries, genderOptions, profileOptions } from '../constants/model';

const UserInfo = ({ item, title }) => {
    const [showTypeGender, setShowTypeGender] = useState(false)
    const [formData, setFormData] = useState({
        name: item.name,
        email: item.email,
        organization: item.organization ?? "",
        country: item.country ?? "",
        age: item.age ?? "",
        gender: item.gender ?? "",
        profile: item.profile ?? ""
    });
    const [password, setPassword] = useState({ current_pass: "", new_pass: "" })
    const [image, setImage] = useState();

    const inputRef = useRef();
    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    const sendRequest = () => {
        if (formData.name !== "" && formData.email !== "") {
            HttpConnection(HttpOperations.user,
                result => {
                    setFormData(result.item);
                    updateStorageUser(result.item);
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSendSuccess" } });
                }, error => dispatch({ action: UserAction.showNoti, data: { show: true, message: "errorSend" } }),
                HttpMethod.put, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } });
        }
    }

    const changePass = () => {
        if (password.current_pass !== "" && password.new_pass !== "") {
            HttpConnection(`${HttpOperations.user}/password`,
                result => {
                    setPassword({ current_pass: "", new_pass: "" })
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSendSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                HttpMethod.put, password);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } });
        }
    }

    const onChangeSelect = (e) => {
        if (e.target.name === "gender") {
            if (e.target.value === dictionary[genderOptions[5]]) {
                setShowTypeGender(true)
                setFormData({ ...formData, [e.target.name]: "" })
            } else {
                setShowTypeGender(false)
                setFormData({ ...formData, [e.target.name]: e.target.value })
            }
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            var image = new Image();
            image.src = URL.createObjectURL(file);

            image.onload = () => {
                URL.revokeObjectURL(image.src);
                setImage({ file: file, url: reader.result });
            };
        }

        if (file !== null)
            reader.readAsDataURL(file)
    }

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onChangePass = (e) => setPassword({ ...password, [e.target.name]: e.target.value });

    return <Fragment>
        <Container fontWeight="300" fontSize="38px" children={<TextLocalized children={title} />} />
        <Container fontWeight="100" fontSize="14px" children={<TextLocalized children="msgUserInfo" />} />

        <ContainerFlex display="none" width="12" pv="16" justify="center">
            <Card image={image ? image.url : null} onClick={() => inputRef.current.click()} shadowOn idealwidth="250px" height="250px" border="solid" borderWidth="4px" borderColor="color-white" radius="50%" color="color-blue-dark" />
            <input onChange={_handleImageChange} ref={inputRef} hidden type="file" />
        </ContainerFlex>

        <InputField inputmv="16" title="msgName" name="name" value={formData.name} onChange={onChange} />
        <InputSelectField title="msgCountry" name="country" options={countries} value={formData.country} onChange={onChangeSelect} />
        <InputField inputmv="16" title="msgAge" type="number" name="age" value={formData.age} onChange={onChange} />
        <InputSelectField title="msgGender" name="gender" options={genderOptions} value={formData.gender} onChange={onChangeSelect} />
        {
            showTypeGender ?
                <InputField inputmt="16" title="msgLetme" type="text" name="gender" value={formData.gender} onChange={onChange} />
                : null
        }
        <InputSelectField inputmv="16" title="msgProfile" name="profile" options={profileOptions} value={formData.profile} onChange={onChangeSelect} />
        <InputField title="msgEmail" name="email" value={formData.email} onChange={onChange} />
        <InputField inputmv="16" title="msgOrganization" name="organization" value={formData.organization} onChange={onChange} />

        <Button onClick={sendRequest} textAlign="center" px="12" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionUpdate" />} />

        <Container fontWeight="300" fontSize="38px" children={<TextLocalized children="msgPassword" />} />
        <Container fontWeight="100" fontSize="14px" children={<TextLocalized children="msgPassInfo" />} />

        <InputField inputmv="16" title="msgCurrentPass" name="current_pass" value={password.current_pass} onChange={onChangePass} />
        <InputField title="msgNewPassword" name="new_pass" value={password.new_pass} onChange={onChangePass} />

        <Button onClick={changePass} textAlign="center" px="12" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionChangePass" />} />

    </Fragment>
}

const AnnounceEditModal = ({ announce, successCallback }) => {
    const [formData, setFormData] = useState({ id: announce.id, text: announce.text, date: format(new Date(announce.date_end), "yyyy-MM-dd") });
    const { dictionary } = useContext(LanguageContext);
    const { dispatch } = useContext(UserContext);
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.text && formData.date) {
            let formAux = { text: formData.text, date_end: new Date(formData.date).getTime() };
            HttpConnection(`${HttpOperations.announces}/${formData.id}`, result => {
                dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
                successCallback();
            }, error => console.log(error), HttpMethod.put, formAux);
        }
    }

    return <Card onClick={e => e.stopPropagation()} shadowOn width="10" lg="6" px="16" color="color-blue-light-a20" radius="12px">
        <Container width="wrap" fontColor="color-black" fontWeight="300" fontSize="48px" children={<TextLocalized children="actionNewThread" />} />
        <InputField inputmv="16" type="date" label="msgDatenEnd" name="date" value={formData.date} onChange={onChange} />

        <Container mb="8" fontColor="color-blue-dark" fontWeight="300" children={<TextLocalized children={"msgText"} />} />
        <Card width="12" color="color-white" border="solid" borderColor="color-blue-dark" fontColor="color-blue-dark" borderWidth="1px">
            <textarea onChange={onChange} name="text" value={formData.text} placeholder={dictionary["msgWriteText"]} rows="5" style={{ padding: "16px" }} />
        </Card>

        <ContainerFlex width="12" justify="flex-end" fontSize="14px">
            <Button onClick={sendRequest} pv="8" ph="64" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionSend" />} />
        </ContainerFlex>
    </Card>
}

const AnnounceItem = ({ item, editItem, deleteItem }) => {
    return <Button mv="8" flex shadowOn width="12" align="center" color="color-white">
        <Card flex justify="center" align="center" idealwidth="64px" height="64px" fontColor="color-blue-dark" children={<Icon path={item.date_end < new Date().getTime() ? mdiBullseye : mdiBullhorn} size={1} />} />
        <Container width="wrap">
            <Container fontWeight="300" children={`${item.text}`} />
            <Container fontWeight="100" fontSize="12px" children={format(new Date(item.date_end), "dd/MM/yyyy")} />
        </Container>
        <Container ph="8" pv="4" fontColor="color-white" textTransform="uppercase" fontSize="13px" >
            <Button onClick={editItem} ph="16" pv="4" mv="8" color="color-blue" hoverColor="color-blue-dark" textAlign="center" children={<TextLocalized children="actionEdit" />} />
            <Button onClick={deleteItem} ph="16" pv="4" mv="8" color="color-red" hoverColor="color-red-dark" children={<TextLocalized children="actionDelete" />} />
        </Container>
    </Button>
}

const UserAnnouncement = () => {
    const [items, setItems] = useState([]);
    const [formData, setFormData] = useState({ text: "", date: "" });
    const [today] = useState(new Date().getTime());
    const languageContext = useContext(LanguageContext);

    const { dispatch } = useContext(UserContext);

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    useEffect(() => getItems(), []);

    const getItems = () => {
        HttpConnection(`${HttpOperations.user}/${HttpOperations.announces}`,
            result => setItems(result.item), error => console.log(error), HttpMethod.get)
    }

    const sendRequest = () => {
        if (formData.text !== "" && formData.date !== "") {
            let formAux = { text: formData.text, date_end: new Date(formData.date).getTime() };
            HttpConnection(HttpOperations.announces,
                result => {
                    getItems();
                    setFormData({ text: "", date: "" });
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSendSuccess" } })
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
                HttpMethod.post, formAux);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    const deleteItem = (item) => HttpConnection(`${HttpOperations.announces}/${item.id}`, result => getItems(),
        error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
        HttpMethod.delete)

    const showEditModal = (item) => {
        dispatch({ action: UserAction.showModal, data: { show: true, body: <AnnounceEditModal announce={item} successCallback={getItems} /> } })
    }

    return <Fragment fontColor="color-black">
        <Container fontWeight="300" fontSize="38px" children={<TextLocalized children="msgNewAnnouncement" />} />
        <Container fontWeight="100" fontSize="14px" children={<TextLocalized children="msgNewAnnouncementInfo" />} />

        <InputField inputmv="16" type="date" label="msgDatenEnd" name="date" value={formData.date} onChange={onChange} />

        <Container mb="8" fontWeight="300" children={<TextLocalized children={"msgText"} />} />
        <Card width="12" color="color-white" border="solid" borderColor="color-black" borderWidth="1px">
            <textarea onChange={onChange} name="text" value={formData.text} placeholder={languageContext.dictionary["msgWriteText"]} rows="5" style={{ padding: "16px" }} />
        </Card>

        <ContainerFlex width="12" justify="flex-end" pb="16" fontSize="14px">
            <Button onClick={sendRequest} pv="12" ph="64" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionAddAnnouncement" />} />
        </ContainerFlex>

        <Container fontWeight="300" fontSize="38px" children={<TextLocalized children="msgAnnouncements" />} />
        <Container fontWeight="100" fontSize="14px" children={<TextLocalized children="msgAnnouncesInfo" />} />
        <ContainerFlex pb="16" width="12" children={items.filter(item => item.date_end > today).map((item, index) => <AnnounceItem key={index} item={item} editItem={() => showEditModal(item)} deleteItem={() => deleteItem(item)} />)} />

        <Container fontWeight="300" fontSize="38px" children={<TextLocalized children="msgAnnouncementsFinished" />} />
        <Container fontWeight="100" fontSize="14px" children={<TextLocalized children="msgAnnouncesInfo" />} />
        <ContainerFlex width="12" children={items.filter(item => item.date_end < today).map((item, index) => <AnnounceItem key={index} item={item} editItem={() => showEditModal(item)} deleteItem={() => deleteItem(item)} />)} />
    </Fragment>
}

const CertificateItem = ({ item }) => {

    const openCertificate = () => {
        window.open(`${serverUrlEndpoint}${HttpOperations.certificates}/${item.crt_file}`);
    }

    return <Button mv="8" flex shadowOn width="12" align="center" color="color-white">
        <Card flex justify="center" align="center" idealwidth="64px" height="64px" fontColor="color-blue-dark" children={<Icon path={mdiTrophy} size={1} />} />
        <Container width="wrap">
            <Container fontWeight="300" children={`Learning Unit ${item.unit.id} - Certificate`} />
            <Container fontWeight="100" fontSize="12px" children={format(new Date(item.create_at), 'dd/MM/yyyy')} />
        </Container>
        <Container ph="8" pv="4">
            <Button onClick={openCertificate} ph="8" mv="8" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" children={<TextLocalized children="actionShow" />} />
        </Container>
    </Button>
}

const UserCertificates = () => {
    const [certificates, setCertificates] = useState([]);

    useEffect(() => {
        HttpConnection(`${HttpOperations.user}/${HttpOperations.certificates}`,
            result => setCertificates(result.item), error => console.log(error), HttpMethod.get)
    }, [])

    const globalCertificate = () => {
        HttpConnection(`${HttpOperations.user}/${HttpOperations.certificates}/final`,
            result => {
                window.open(`${serverUrlEndpoint}${HttpOperations.certificates}/${result.item.crt_file}`)
            },
            error => console.log(error), HttpMethod.get)
    }

    return <Fragment fontColor="color-black">
        <Container fontWeight="300" fontSize="38px" children={<TextLocalized children="msgMyCertificates" />} />
        <Container mb="16" fontWeight="100" fontSize="14px" children={<TextLocalized children="msgMyCertificatesInfo" />} />

        <ContainerFlex width="12" children={certificates.map((item, index) => <CertificateItem key={index} item={item} />)} />
        {
            certificates.length === 4 ?
                <ContainerFlex width="12" justify="flex-end">
                    <Button onClick={globalCertificate} textAlign="center" px="12" mv="16" fontWeight="300" color="color-blue" hoverColor="color-blue-dark"
                        fontColor="color-white" children={<TextLocalized children="actionCourseCertificate" />} />
                </ContainerFlex>
                : null
        }
    </Fragment>
}

const Profile = () => {
    const [item, setItem] = useState();

    useEffect(() => {
        HttpConnection(HttpOperations.user, result => setItem(result.item), error => console.log(error), HttpMethod.get);
    }, [])

    return (
        <ContainerFlex width="12" justify="center" pv="32" minheight="100vh" color="color-blue-light-a20">
            {
                item ?
                    <ContainerFlex width="12" lg="8" justify="center" align="flex-start">
                        <Container width="12" lg="4" px="16" children={<UserInfo item={item} title={item.role.id === 2 ? "msgUserProfile" : "msgTeacherProfile"} />} />
                        <Container width="12" lg="8" px="16" children={item.role.id === 2 ? <UserCertificates /> : <UserAnnouncement />} />
                    </ContainerFlex>
                    : null
            }
        </ContainerFlex>
    );
};

export default Profile;