import { mdiBookAlphabet, mdiSchoolOutline, mdiDomain, mdiAccountHardHat, mdiDownloadBox } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LearningUnit from '../components/LearningUnit';
import PillItem from '../components/PillItem';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { UserAction, UserContext } from '../components/provider/UserProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations, serverUrlEndpoint } from '../components/util/HttpConnection';
import { countries } from '../constants/model';
import home from '../images/home.jpg';
import outcome from '../images/outcomes.png';

import partner_1 from '../images/partner_1.svg';
import partner_2 from '../images/partner_2.png';
import partner_3 from '../images/partner_3.png';
import partner_4 from '../images/partner_4.png';
import partner_5 from '../images/partner_5.png';
import partner_6 from '../images/partner_6.png';
import InputField from '../components/InputField';

const LearningUnitSection = () => {
    const [items, setItems] = useState([]);
    const history = useNavigate();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        HttpConnection(`${HttpOperations.units}?lang=${language.id}`, result => setItems(result.item), error => console.log(error), HttpMethod.get);
    }, [language])

    return <ContainerFlex width="12" justify="center" align="center" pv="64" color="color-blue-light-a20">
        <ContainerFlex width="12" lg="8" justify="center" align="stretch">
            <ContainerFlex width="12" align="center" fontSize="46px" fontColor="color-black">
                <Container fontWeight="bold" children={<TextLocalized children="msgLearningUnits" />} />
            </ContainerFlex>
            <ContainerFlex width="12" pv="32" justify="center" align="stretch">
                {items.slice(0, 4).map((item, index) => <LearningUnit key={index} item={item} showItem={() => history(`/unit/${item.id}`)} />)}
            </ContainerFlex>
        </ContainerFlex>
    </ContainerFlex>
}

const PillsSection = () => {
    const [items, setItems] = useState([]);
    const history = useNavigate();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        HttpConnection(`${HttpOperations.pills}?lang=${language.id}`, result => setItems(result.item), error => console.log(error), HttpMethod.get);
    }, [language])

    return <ContainerFlex width="12" justify="center" minheight="1500px" color="color-gray-light">
        <ContainerFlex width="12" lg="8" pv="48" justify="center" align="center">
            <ContainerFlex width="12" align="center" fontSize="46px" fontColor="color-black">
                <Container fontWeight="bold" children={<TextLocalized children="msgLatestPills" />} />
                <Container width="12" textAlign="start" fontSize="16px" fontColor="color-black" children={<TextLocalized children="msgLatestPillsText" />} />
            </ContainerFlex>
            <ContainerFlex width="12" pv="32" justify="center" align="stretch">
                {items.map((item, index) => <PillItem key={index} item={item} showItem={() => history(`/pill/${item.id}`)} />)}
            </ContainerFlex>
        </ContainerFlex>
    </ContainerFlex>
}

const ContactSection = () => {
    const [formData, setFormData] = useState({ name: "", email: "", country: "", message: "" });
    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.name !== "" && formData.email !== "" && formData.message !== "" && formData.country !== "") {
            HttpConnection(HttpOperations.contact,
                response => {
                    //Reset form
                    setFormData({ name: "", email: "", message: "", country: "" });
                    //Show user
                    dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSendSuccess" } });
                },
                error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorLogin" } }),
                HttpMethod.post, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } });
        }
    }

    return <ContainerFlex width="12" justify="center">
        <ContainerFlex width="12" lg="8" pv="48" justify="center" align="center">
            <ContainerFlex align="center" fontWeight="bold" fontSize="46px" fontColor="color-black" children={<TextLocalized children="msgContact" />} />
            <ContainerFlex width="12" pv="32" justify="center" align="flex-start">
                <Container width="6" justify="center">

                    <InputField inputmv="16" title="msgName" name="name" value={formData.name} onChange={onChange} />
                    <InputField inputmv="16" title="msgEmail" name="email" value={formData.email} onChange={onChange} />

                    <InputField title="msgCountry" name="country" options={countries} value={formData.country} onChange={onChange} />
                    <Card width="12" mv="16" radius="10px" color="color-white" border="solid" borderColor="color-blue-dark" borderWidth="1px" children={<textarea onChange={onChange} className="col" rows="5" style={{ padding: "16px" }} name="message" value={formData.message} placeholder={dictionary["msgMessage"]} />} />

                    <Container width="12" mv="16" textAlign="center" children={<TextLocalized children="msgTerms" />} />
                    <Button width="wrap" mt="50" onClick={sendRequest} textAlign="center" px="12" fontWeight="300" color="color-blue" hoverColor="color-blue-dark" textTransform="uppercase" fontColor="color-white" children={<TextLocalized children="actionSend" />} />
                </Container>
            </ContainerFlex>
        </ContainerFlex>
    </ContainerFlex>
}

const UserTypeInfo = ({ icon, title, text }) => {
    return <ContainerFlex width="6" justify="center">
        <Card flex radius="15px" border="solid" borderColor="color-black" borderWidth="2px" color="color-black" fontColor="color-white" px="16" children={<Icon size="24px" path={icon} />} />
        <ContainerFlex width="wrap" ph="16">
            <Container width="12" textAlign="left" fontWeight="bold" fontSize="24px" fontColor="color-black" children={<TextLocalized children={title} />} />
            <Container width="12" mb="16" textAlign="left" fontSize="14px" fontColor="color-black" children={<TextLocalized children={text} />} />
        </ContainerFlex>
    </ContainerFlex>
}

const Home = () => {

    const { language } = useContext(LanguageContext);

    const openManual = () => {
        window.open(`${serverUrlEndpoint}${HttpOperations.docs}/user_manual_${language.id}.pdf`)
    }

    return (
        <Container width="12">

            <ContainerFlex width="12" image={home}>
                <ContainerFlex width="12" justify="center" color="color-gray-alpha">
                    <Container width="12" lg="8" pv="120" justify="center" align="center" fontColor="color-white">
                        <Container width="12" ph="16" fontWeight="bold" fontSize="54px" children={<TextLocalized children="msgExploreUnits" />} />
                        <Container width="12" ph="16" pv="16" fontWeight="300" fontSize="18px" children={<TextLocalized children="msgExploreUnitsText" />} />
                    </Container>
                </ContainerFlex>

            </ContainerFlex>

            <LearningUnitSection />

            <ContainerFlex width="12" color="color-brown-light-a40" justify="center">
                <ContainerFlex width="12" lg="8" pv="48" justify="center" align="center">
                    <Container width="12">
                        <Container width="12" mb="16" fontWeight="bold" fontSize="46px" fontColor="color-black" children={<TextLocalized children="msgWooLearningPlatform" />} />
                        <Container width="12" fontSize="16px" fontColor="color-black" children={<TextLocalized children="msgWooLearningPlatformText" />} />
                    </Container>

                    <ContainerFlex width="12" ph="16" pv="32" justify="center">
                        <ContainerFlex width="12" mt="32" justify="center">
                            <UserTypeInfo icon={mdiBookAlphabet} title="msgStudents" text="msgStudentsText" />
                            <UserTypeInfo icon={mdiSchoolOutline} title="msgEducators" text="msgEducatorsText" />
                        </ContainerFlex>

                        <ContainerFlex width="12" mt="16" justify="center">
                            <UserTypeInfo icon={mdiDomain} title="msgCompanies" text="msgCompaniesText" />
                            <UserTypeInfo icon={mdiAccountHardHat} title="msgProfessionals" text="msgProfessionalsText" />
                        </ContainerFlex>
                    </ContainerFlex>

                    <ContainerFlex width={"12"} justify="center">
                        <Button width="auto" mt="16" onClick={openManual} flex align="center" radius="10px"
                            border="solid" borderColor="color-black" borderWidth="2px" color="color-black" hoverColor="color-black-a40" fontWeight="bold" fontColor="color-white" ph="16" pv="8">
                            <Icon size="24px" path={mdiDownloadBox} />
                            <Container ml="16" fontSize="14" textTransform="uppercase" children={<TextLocalized children="actionDownloadInfo" />} />
                        </Button>
                    </ContainerFlex>

                    <ContainerFlex display="none" width="12" lg="6" ph="16" justify="center" children={<img alt="outcome" src={outcome} width="90%" />} />
                </ContainerFlex>
            </ContainerFlex>

            <PillsSection />

            <ContainerFlex width="12" color="color-blue-light-a20" justify="center">
                <ContainerFlex width="12" lg="8" pv="64" justify="center" align="center">
                    <Container width="12" textAlign="center" pb="16" fontSize="46px" fontWeight="bold" children={<TextLocalized children="msgPartners" />} />
                    {[partner_5, partner_1, partner_2, partner_6, partner_3, partner_4].map((item, index) => <img key={index} alt="partner" style={{ margin: "16px" }} src={item} height="70px" />)}
                </ContainerFlex>
            </ContainerFlex>

            <ContactSection />
        </Container >
    );
};

export default Home;

