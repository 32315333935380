import { mdiBookAlphabet } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CompetenceItem from '../components/CompetenceItem';
import KeywordItem from '../components/KeywordItem';
import LearningUnit from '../components/LearningUnit';
import PillItem from '../components/PillItem';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations, imageURL, serverUrlEndpoint } from '../components/util/HttpConnection';

const ProgressionOption = ({ item }) => {
    return <Button onClick={() => window.open(item.link, "_blank")} flex align="center" radius="15px" mr="16" mb="8"
        border="solid" borderColor="color-black" borderWidth="2px" color="color-black" hoverColor="color-black-a40"
        fontWeight="bold" fontColor="color-white" ph="32" pv="8" textTransform="uppercase" children={item.name} />
}

const LearningUnitDetail = () => {
    const [item, setItem] = useState();
    const [units, setUnits] = useState([]);

    let history = useNavigate();
    let params = useParams();
    const { language, dictionary } = useContext(LanguageContext);

    useEffect(() => {
        HttpConnection(`${HttpOperations.units}/${params.id}?lang=${language.id}`, result => {
            setItem(result.item);
            HttpConnection(`${HttpOperations.units}/nexts/${result.item.order}?lang=${language.id}`, result => setUnits(result.item), error => console.log(error), HttpMethod.get);
        }, error => console.log(error), HttpMethod.get)
    }, [params.id, language]);

    const getCompetences = () => {
        let competences = [];
        item.pills.forEach(pill => {
            competences = competences.concat(pill.content.competences)
        });
        return competences;
    }

    const getTopics = () => {
        let topics = [];
        item.pills.forEach(pill => {
            topics = topics.concat(pill.content.topics)
        });
        return topics;
    }

    const openDocs = (item) => {
        window.open(`${serverUrlEndpoint}${HttpOperations.docs}/${item}`);
    }

    return (
        <Container width="12" minheight="calc(100vh - 64px)">
            {
                item ?
                    <Fragment>
                        <ContainerFlex width="12" image={imageURL(item.image)} >
                            <ContainerFlex width="12" pv="64" color="color-gray-alpha" justify="center">

                                <Container width="12" lg="8" pv="32" ph="16" fontColor="color-white">
                                    <Container width="12" mb="16" fontWeight="bold" fontSize="74px" children={item.content.title} />
                                    <Container width="12" fontWeight="300" fontSize="18px" children={item.content.description} />
                                </Container>

                                <ContainerFlex width="12" lg="8" pv="28" justify="center" align="stretch">
                                    {getTopics().map(keyword => <KeywordItem key={keyword.id} item={keyword} />)}
                                </ContainerFlex>
                                <ContainerFlex width="12" lg="8" pb="60" justify="center" align="stretch">
                                    {item.goals?.map(goal => <Card key={goal.id} idealwidth="64px" height="64px" mh="8" image={imageURL(goal.image)} />)}

                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>


                        <ContainerFlex width="12" justify="center" color="color-blue-light-a20">
                            <ContainerFlex width="12" lg="8" pt="28" pb="48" justify="center" align="stretch">
                                {item.pills.map((item, index) => <PillItem key={index} item={item} showItem={() => history(`/pill/${item.id}`)} />)}
                            </ContainerFlex>

                            <ContainerFlex width="12" justify="center" color="color-brown" pv="48" ph="8">
                                <Card flex shadowOn width="12" lg="8" pv="28" ph="16" justify="center" color="color-white">
                                    <Container width="12" fontWeight="600" textAlign="center" mb="16" fontSize="22px" children={<TextLocalized children="msgCompetencesUnitTitle" />} />
                                    {getCompetences().map(competence => <CompetenceItem key={competence.id} item={competence} />)}

                                    <Card width="10" mt="32" px="28" textAlign="center" border="solid" borderColor="color-black" borderWidth="1px"
                                        children={<TextLocalized children="msgExamAlert" />} />

                                    <ContainerFlex width="12" mt="32" justify="center" pv="18">
                                        <Button onClick={() => history(`/unit/${item.id}/test`, { item: item })} flex width="auto" textAlign="center" fontWeight="300" mh="32" pv="16" ph="64" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionStartTest" />} />
                                        <Container width="12" textAlign="center" ph="16" mt="16" fontWeight="300" fontSize="16px" fontColor="color-black" children={<TextLocalized children="msgStartTestSubtitle" />} />
                                    </ContainerFlex>
                                </Card>
                            </ContainerFlex>
                        </ContainerFlex>



                        <ContainerFlex width="12" justify="center">
                            <ContainerFlex width="12" lg="8" pt="48" ph="8" align="center">
                                <Container width="12" align="center" fontWeight="bold" fontSize="36px" fontColor="color-black" children={<TextLocalized children="msgAssignment" />} />
                                <Card width="12" mb="32" children={<TextLocalized children="msgAssignmentText" />} />

                                <ContainerFlex width="8" justify="space-between">
                                    {
                                        item.content.link_one ?
                                            <Button onClick={() => openDocs(item.content.link_one)} flex align="center" radius="15px"
                                                border="solid" borderColor="color-black" borderWidth="2px" color="color-black" hoverColor="color-black-a40" fontWeight="bold" fontColor="color-white" ph="32" pv="8">
                                                <Icon size="48px" path={mdiBookAlphabet} />
                                                <Container ml="16" textTransform="uppercase" children={<TextLocalized children="msgForStudents" />} />
                                            </Button>
                                            : null
                                    }

                                    {
                                        item.content.link_two ?
                                            <Button onClick={() => openDocs(item.content.link_two)} flex align="center" radius="15px"
                                                border="solid" borderColor="color-black" borderWidth="2px" color="color-black" hoverColor="color-black-a40" fontWeight="bold" fontColor="color-white" ph="32" pv="8">
                                                <Icon size="48px" path={mdiBookAlphabet} />
                                                <Container ml="16" textTransform="uppercase" children={dictionary["msgForTeachers"].replace('%d', item.id)} />
                                            </Button>
                                            : null
                                    }

                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>

                        <ContainerFlex width="12" justify="center">
                            <ContainerFlex width="12" lg="8" pv="48" ph="8" justify="center" align="center">
                                <Container width="12" mb="16" align="center" fontWeight="bold" fontSize="36px" fontColor="color-black" children={<TextLocalized children="msgProgressionOptions" />} />
                                <Container width="12" pb="32" fontWeight="300" fontSize="18px" fontColor="color-black" children={<TextLocalized children="msgProgressionOptionsSubtitle" />} />

                                <ContainerFlex width="12">
                                    {item.content.options.map(option => <ProgressionOption key={option.id} item={option} />)}
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                        {
                            units.length > 0 ?
                                <ContainerFlex width="12" justify="center" color="color-brown-light-a40">
                                    <ContainerFlex width="12" lg="8" pv="48" ph="8" justify="center" align="center">
                                        <Fragment>
                                            <Container width="12" mb="16" align="center" fontWeight="bold" fontSize="36px" fontColor="color-black" children={<TextLocalized children="msgNextLearning" />} />
                                            <ContainerFlex width="12" pb="64" align="stretch" justify="center">
                                                {units.map((item, index) => <LearningUnit key={index} item={item} showItem={() => history(`/unit/${item.id}`)} />)}
                                            </ContainerFlex>
                                        </Fragment>
                                    </ContainerFlex>
                                </ContainerFlex>
                                : null
                        }
                    </Fragment>
                    : null
            }
        </Container >
    );
};

export default LearningUnitDetail;