import { mdiChevronLeft, mdiPresentation, mdiTextBox } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CompetenceItem from '../components/CompetenceItem';
import { LanguageContext } from '../components/provider/LanguageProvider';
import ResourceItem from '../components/ResourceItem';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import TopicItem from '../components/TopicItem';
import { HttpConnection, HttpMethod, HttpOperations, imageURL, serverUrlEndpoint } from '../components/util/HttpConnection';
import LearningUnit from '../components/LearningUnit';

const PillDetail = () => {
    const [item, setItem] = useState();
    const [units, setUnits] = useState([]);

    const history = useNavigate();
    const params = useParams();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        HttpConnection(`${HttpOperations.pills}/${params.id}?lang=${language.id}`, result => {
            setItem(result.item)
            if (result.item && result.item.order === 3) {
                HttpConnection(`${HttpOperations.units}/nexts/${result.item.unit.order}?lang=${language.id}`, result => setUnits(result.item), error => console.log(error), HttpMethod.get);
            }
        }, error => console.log(error), HttpMethod.get)
    }, [params.id, language]);

    const nextPill = () => {
        let index = item.unit.pills.findIndex(pill => pill.id === item.id)
        history(`/pill/${item.unit.pills[index + 1].id}`)
    }

    const openDocs = (item) => {
        window.open(`${serverUrlEndpoint}${HttpOperations.docs}/${item}`);
    }

    return (
        <Container width="12" minheight="calc(100vh - 64px)">
            {
                item ?
                    <Fragment>

                        <ContainerFlex width="12" justify="center" image={imageURL(item.image)}>
                            <ContainerFlex width="12" pv="32" justify="center" align="center" color="color-gray-alpha">
                                <ContainerFlex width="12" justify="center" align="center">
                                    <ContainerFlex width="12" lg="8" ph="16" justify="flex-start" >
                                        <Button onClick={() => history(`/unit/${item.unit.id}`)} flex align="center" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" ph="16" pv="8" radius="10px">
                                            <Icon path={mdiChevronLeft} size={1} />
                                            <ContainerFlex cursor="pointer" ph="8" fontSize="18px" fontWeight="300" justify="center" align="center" textTransform="uppercase" children={<TextLocalized children="msgBackToUnit" />} />
                                        </Button>
                                    </ContainerFlex>
                                    <Container width="12" lg="8" fontColor="color-white" mt="32">
                                        <Container width="12" ph="16" fontWeight="bold" fontSize="74px" children={item.content.title} />
                                        <Container width="12" ph="16" pv="16" fontWeight="300" fontSize="18px" children={item.content.summary} />
                                    </Container>

                                </ContainerFlex>

                                <ContainerFlex width="12" justify="center" mt="16" mb="64" ph="16">
                                    <Card shadowOn width="12" lg="8" pv="28" textAlign="center" color="color-white" borderWidth="1px">
                                        <Container width="12" textAlign="center" pv="8" ph="16" fontWeight="600" fontSize="22px" children={<TextLocalized children="msgCompetencesTitle" />} />
                                        {item.content.competences.map((item, index) => <CompetenceItem key={index} item={item} />)}
                                    </Card>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>

                        <ContainerFlex width="12" color="color-white" justify="center">
                            <ContainerFlex width="12" lg="8" ph="16" mv="64" justify="center" align="center" fontColor="color-black">
                                <ContainerFlex width="12" justify="center" align="center" fontWeight="bold" fontSize="46px" children={<TextLocalized children="msgTopics" />} />
                                <ContainerFlex width="12" pv="32" fontSize="18px" fontWeight="50" children={item.content.topics.map((item, index) => <TopicItem key={index} item={item} index={index} />)} />

                                {
                                    item.content.key_resource_type_id === 2 ?
                                        <Card shadowOn flex width="12" height="200px" justify="center" align="center" border="solid" borderWidth="1px" borderColor="color-white" image={imageURL(item.image)} radius="10px">
                                            <Button onClick={() => openDocs(item.content.key_resource)}
                                                color="color-black" hoverColor="color-black-a40" fontColor="color-white"
                                                border="solid" borderWidth="2px" borderColor="color-black"
                                                fontWeight="300" fontSize="18px" mh="16" pv="16" ph="32"
                                                children={<TextLocalized children="actionOpenResource" />} />
                                        </Card>
                                        :
                                        <Fragment>
                                            <Card flex shadowOn width="12" justify="space-around">
                                                <iframe width="100%" height="600" style={{ borderRadius: "12px" }} src={item.content.key_resource} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </Card>
                                            <ContainerFlex width={"12"} justify="center" mh="-8">
                                                <Button width="auto" mt="16" mh="8" onClick={() => window.open(`${serverUrlEndpoint}${HttpOperations.docs}/${item.content.key_resource_link_one}`)} flex align="center" radius="10px"
                                                    border="solid" borderColor="color-black" borderWidth="2px" color="color-black" hoverColor="color-black-a40" fontWeight="bold" fontColor="color-white" ph="16" pv="8">
                                                    <Icon size="24px" path={mdiTextBox} />
                                                    <Container ml="16" fontSize="14" textTransform="uppercase" children={<TextLocalized children="actionDownloadScript" />} />
                                                </Button>
                                                <Button width="auto" mt="16" mh="8" onClick={() => window.open(`${serverUrlEndpoint}${HttpOperations.docs}/${item.content.key_resource_link_two}`)} flex align="center" radius="10px"
                                                    border="solid" borderColor="color-black" borderWidth="2px" color="color-black" hoverColor="color-black-a40" fontWeight="bold" fontColor="color-white" ph="16" pv="8">
                                                    <Icon size="24px" path={mdiPresentation} />
                                                    <Container ml="16" fontSize="14" textTransform="uppercase" children={<TextLocalized children="actionDownloadPresentation" />} />
                                                </Button>
                                            </ContainerFlex>
                                        </Fragment>
                                }
                            </ContainerFlex>
                        </ContainerFlex>


                        <ContainerFlex width={12} justify="center" color="color-brown-light">

                            <ContainerFlex width="12" lg="8" mv="64" justify="center" align="flex-start" px="16">
                                {
                                    item.content.how_apply ?
                                        <Card shadowOn width="12" color="color-white">
                                            <Card width="12" px="16" fontWeight="bold" fontSize="16px" color={"color-black"} hoverColor="color-black-a40" fontColor="color-white"
                                                border="solid" borderWidth="2px" borderColor="color-black" textTransform="uppercase"
                                                radius="0" radiustr="8px" radiustl="8px" textAlign="center"
                                                children={<TextLocalized children="msgHowApply" />} />

                                            <ContainerFlex width={12} px="16" align="stretch">
                                                <ContainerFlex width="12" pv="16" xl="wrap">
                                                    <pre dangerouslySetInnerHTML={{ __html: item.content.how_apply }} style={{ fontFamily: "Poppins", margin: "0px", maxWidth: "100%", whiteSpace: "pre-wrap", overflowY: "hidden" }}>
                                                    </pre>
                                                </ContainerFlex>

                                                {
                                                    item.image_how ?
                                                        <ContainerFlex width="12" xl="wrap" px="16" align="center">
                                                            <Container>
                                                                <img alt="how_img" src={imageURL(item.image_how)} width="100%" />
                                                                {
                                                                    item.image_how_two ?
                                                                        <img alt="how_img" src={imageURL(item.image_how_two)} width="100%" />
                                                                        : null
                                                                }
                                                                <Container mt="16" width="12" textAlign="center" fontSize="12px" fontColor="color-black" children={item.content.how_apply_alt} />
                                                            </Container>
                                                        </ContainerFlex>
                                                        : null
                                                }
                                            </ContainerFlex>
                                        </Card>
                                        : null
                                }

                                {
                                    item.content.case_study ?
                                        <Card shadowOn width="12" color="color-white" mt="16">
                                            <Card width="12" px="16" fontWeight="bold" fontSize="16px" color={"color-black"} hoverColor="color-black-a40" fontColor="color-white"
                                                border="solid" borderWidth="2px" borderColor="color-black" textTransform="uppercase"
                                                radiustl="8px" radiustr="8px" radius="0" textAlign="center"
                                                children={<TextLocalized children="msgCaseStudy" />} />

                                            <ContainerFlex width={12} px="16" align="stretch">
                                                {
                                                    item.image_case ?
                                                        <ContainerFlex width="12" xl="wrap" px="16" align="center">
                                                            <Container>
                                                                <img alt="how_img" src={imageURL(item.image_case)} width="100%" />
                                                                {
                                                                    item.image_case_two ?
                                                                        <img alt="how_img" src={imageURL(item.image_case_two)} width="100%" />
                                                                        : null
                                                                }
                                                                {
                                                                    item.image_case_three ?
                                                                        <img alt="how_img" src={imageURL(item.image_case_three)} width="100%" />
                                                                        : null
                                                                }
                                                                <Container mt="16" width="12" textAlign="center" fontSize="12px" fontColor="color-black" children={item.content.case_study_alt} />
                                                            </Container>
                                                        </ContainerFlex>
                                                        : null
                                                }

                                                <Container width="12" pv="16" xl="wrap">
                                                    <pre dangerouslySetInnerHTML={{ __html: item.content.case_study }} style={{ fontFamily: "Poppins", margin: "0px", maxWidth: "100%", whiteSpace: "pre-wrap", overflowY: "hidden" }}>
                                                    </pre>
                                                </Container>

                                            </ContainerFlex>
                                        </Card>
                                        : null
                                }

                                {
                                    item.content.interview_url ?
                                        <ContainerFlex width={12} mt="16">
                                            <Card
                                                width="wrap" textAlign="center" textTransform="uppercase"
                                                color="color-black" fontColor="color-white"
                                                radiusbl="0" radiusbr="0"
                                                fontWeight="300" fontSize="18px" pv="16" ph="32"
                                                children={<TextLocalized children="msgInterview" />} />

                                            <Card flex shadowOn width="12">
                                                <iframe width="100%" height="500" style={{ borderRadius: "0 0 12px 12px" }} src={item.content.interview_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </Card>
                                        </ContainerFlex>
                                        : null
                                }
                            </ContainerFlex>

                        </ContainerFlex>

                        <ContainerFlex width="12" color="color-white" justify="center" align="flex-start">
                            <ContainerFlex width="12" lg="8" mv="64" justify="center" align="flex-start">
                                <ContainerFlex width="12" lg="12" ph="16" align="center" fontWeight="bold" fontSize="46px" children={<TextLocalized children="msgResources" />} />
                                <Container width="12" ph="16" pb="32" fontWeight="300" fontSize="18px" fontColor="color-black" children={<TextLocalized children="msgResourcesSubtitle" />} />
                                <ContainerFlex width="12" order="2" lg="12" ph="16" fontSize="18px" fontWeight="50">
                                    {item.content.resources.map((item, index) => <ResourceItem key={index} item={item} index={index} />)}
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>

                        <ContainerFlex width="12" justify="center" mt="18">
                        </ContainerFlex>

                        <ContainerFlex width="12" align="stretch" justify="center" pv="18">
                            <Button onClick={() => history("/forum")} flex width="auto" align="center" textAlign="center" fontWeight="300" fontSize="18px" mh="16" pv="8" ph="64" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="msgForum" />} />
                            {
                                item && item.order < 3 ?
                                    <Button onClick={nextPill} flex width="auto" textAlign="center" fontWeight="300" fontSize="18px" mh="16" pv="8" ph="64" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionNext" />} />
                                    : item ?
                                        <Fragment>
                                            <Button onClick={() => history(`/unit/${item.unit.id}/test`, { item: item.unit })} flex width="auto" textAlign="center" fontWeight="300" fontSize="18px" mh="16" pv="16" ph="64" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" children={<TextLocalized children="actionStartTest" />} />
                                            <Container width="12" ph="16" mt="16" mb="32" fontWeight="300" fontSize="18px" fontColor="color-black" textAlign="center" children={<TextLocalized children="msgStartTestSubtitle" />} />
                                        </Fragment>
                                        : null
                            }
                        </ContainerFlex>
                        {
                            units.length > 0 ?
                                <ContainerFlex width="12" justify="center" color="color-brown-light-a40">
                                    <ContainerFlex width="12" lg="8" pv="48" ph="8" justify="center" align="center">
                                        <Fragment>
                                            <Container width="12" mb="16" align="center" fontWeight="bold" fontSize="36px" fontColor="color-black" children={<TextLocalized children="msgNextLearning" />} />
                                            <ContainerFlex width="12" pb="64" align="stretch" justify="center">
                                                {units.map((item, index) => <LearningUnit key={index} item={item} showItem={() => history(`/unit/${item.id}`)} />)}
                                            </ContainerFlex>
                                        </Fragment>
                                    </ContainerFlex>
                                </ContainerFlex>
                                : null
                        }
                    </Fragment>
                    : null
            }
        </Container >
    );
};

export default PillDetail;