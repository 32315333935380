import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import LearningUnit from '../components/LearningUnit';
import { LanguageContext } from '../components/provider/LanguageProvider';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import home from '../images/home.jpg';

const LearningUnitSection = () => {
    const [items, setItems] = useState([]);
    const history = useNavigate();
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        HttpConnection(`${HttpOperations.units}?lang=${language.id}`, result => setItems(result.item), error => console.log(error), HttpMethod.get);
    }, [language])

    return <ContainerFlex width="12" justify="center" minheight="900px">
        <ContainerFlex width="12" lg="8" pb="64" justify="center" align="stretch">
            {items.map((item, index) => <LearningUnit key={index} item={item} showItem={() => history(`/unit/${item.id}`)} />)}
        </ContainerFlex>
    </ContainerFlex>
}

const LearningUnitList = () => {

    return (
        <Container width="12" minheight="calc(100vh - 64px)" color="color-blue-light-a20">

            <ContainerFlex width="12" justify="center">
                <ContainerFlex width="12" lg="8" pv="32" justify="center" align="center">
                    <Container width="12" ph="16" fontWeight="bold" fontSize="74px" fontColor="color-black" children={<TextLocalized children="msgLearningUnits" />} />
                </ContainerFlex>
            </ContainerFlex>

            <LearningUnitSection />

        </Container >
    );
};

export default LearningUnitList;