import Icon from '@mdi/react';
import { mdiCalendar, mdiCommentOutline, mdiSchoolOutline } from '@mdi/js';

import React from 'react';
import { Button, Card, Container, ContainerFlex } from './styled/Styled';
import { format } from 'date-fns';

const ThreadItem = ({ item, showItem }) => {
    return (
        <Button shadowOn onClick={() => showItem(item)} flex width="12" mv="16" align="flex-stretch" color="color-white">
            <ContainerFlex ph="8" width="wrap" align="center" px="8">
                <Container>
                    <Container width={12} mb="8" fontSize="18px" fontWeight="300" children={item.title} />
                    <ContainerFlex fontColor="color-white">
                        <Card flex align="center" color="color-black" ph="8" pv="4">
                            {
                                item.owner.role.id === 3
                                    ? <Icon style={{ marginRight: "8px" }} path={mdiSchoolOutline} size={"14px"} />
                                    : null
                            }
                            <Container fontSize="10px" fontWeight="100" children={item.owner.name} />
                        </Card>

                        <Card flex mh="8" ph="8" pv="4" align="center" color="color-black" fontSize="10px" fontWeight="100">
                            <Icon style={{ marginRight: "8px" }} path={mdiCalendar} size={"14px"} />
                            <Container children={format(new Date(item.date_create), "dd/MM/yyyy")} />
                        </Card>
                    </ContainerFlex>

                </Container>
            </ContainerFlex>

            <ContainerFlex px="16" minwidth="80px" align="center" color="color-blue" fontColor="color-white" direction="column">
                <Container pb="5" fontSize="18px" fontWeight="300" children={item.num_messages} />
                <Icon path={mdiCommentOutline} size={"12px"} />
            </ContainerFlex>

        </Button>
    );
};

export default ThreadItem;