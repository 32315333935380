import React from 'react';
import Icon from '@mdi/react';

import { Container, ContainerFlex, TextLocalized, Button } from './styled/Styled';

import logo_ue from '../images/logo_ue.png'
import name_white from '../images/name_white.png'

import { mdiEmail, mdiEmailNewsletter } from '@mdi/js';
import { useNavigate } from 'react-router-dom';

export const Footer = () => {

    const history = useNavigate();

    return <Container fontColor="color-white">
        <ContainerFlex display="none" px="60" color="colorgreen" justify="space-between"
            align="center" fontSize="30px" fontWeight="bold">
            <TextLocalized children="msgFooterSup" />

            <Button onclick={() => history("../contact")} shadow={false} mx="16" ph="64" pv="16"
                radius="50px" fontSize="17px" fontWeight="normal" fontColor="colorgreen"
                border="solid" borderWidth="2px" borderColor="colorwhite" color="color-white"
                textAlign="center">
                <TextLocalized children="msgBtnTwo" />
            </Button>
        </ContainerFlex>

        <ContainerFlex pt="40" justify="center" align="center" color="color-black" fontColor="colorwhite" fontSize="12px">

            <Container textAlign="center" justify="center" ph="32" width="12" lg="3">
                <Button shadow={false} onClick={() => window.open("https://woodcircle.net", '_blank')}>
                    <img width="200" alt="logo_peu" src={name_white} />
                </Button>
            </Container>

            <ContainerFlex px="32" justify="center" width="12" lg="6">
                <Container mh="8" textAlign="center">
                    <TextLocalized children="msgFooterContact" />
                    <ContainerFlex justify="center" mv="16">
                        <Button onClick={() => window.open("https://www.woodcircle.net/blank-2", '_blank')} border="solid" borderWidth="2px" borderColor="color-white" px="8" radius="50%" maxheight="68px" mh="16">
                            <Icon path={mdiEmail} size={2} />
                        </Button>
                    </ContainerFlex>
                </Container>

                <Container mh="8" textAlign="center">
                    <TextLocalized children="msgFooterNews" />
                    <ContainerFlex justify="center" mv="16">
                        <Button onClick={() => window.open("https://www.woodcircle.net/s-projects-side-by-side", '_blank')} border="solid" borderWidth="2px" borderColor="color-white" px="8" radius="50%" maxheight="68px" mh="16">
                            <Icon path={mdiEmailNewsletter} size={2} />
                        </Button>
                    </ContainerFlex>
                </Container>
            </ContainerFlex>

            <ContainerFlex align="center" justify="center" width="12" lg="3" pv="10" ph="32" color="colorblack">
                <Container width="6" lg="12" px="8" children={<img width="100%" alt="img" src={logo_ue} />} />
                <Container width="6" lg="12" px="8" align="center" textColor="colorwhite">
                    <TextLocalized children="msgFooterEu" />
                    <Container width="12" pv="8" children={<TextLocalized children="msgFooterGr" />} />
                </Container>
            </ContainerFlex>

            <Container minheight="1px" width="12" color="color-white" />

            <ContainerFlex ph="32" pv="16" width="12" lg="12" textAlign="center" align="center">
                <Container width="auto" fontWeight="bold" children={<TextLocalized children="msgCopyright" />} />

                <Button onClick={() => window.open("https://www.cenfim.org/en/legal-notice", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterLegal" />} />
                <Button onClick={() => window.open("https://www.cenfim.org/en/privacy-policy", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterPrivacy" />} />
                <Button onClick={() => window.open("https://www.cenfim.org/en/cookie-policy", '_blank')} shadow={false} mh="16" mv="8" children={<TextLocalized children="msgFooterCookies" />} />
            </ContainerFlex>
        </ContainerFlex>


    </Container>

}
