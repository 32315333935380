import React from 'react';
import { Button, Card, Container, ContainerFlex, TextLocalized } from './styled/Styled';
import { imageURL } from './util/HttpConnection';

const LearningUnit = ({ item, showItem }) => {
    return (
        <Container width="12" lg="6" px="8">
            <Card flex shadowOn minheight="100%" color="color-white" justify="center" direction="column">
                <Card flex width="12" minheight="150px" radiusbl="0" radiusbr="0" image={imageURL(item.image)} />

                <ContainerFlex width="12" expand="true" height="100%" direction="column" justify="space-between">
                    <Container width="12">
                        <Container width="12" textAlign="center" px="8" fontWeight="bold" fontSize="18px" children={`${item.content.title ?? ""}`} />
                        <Container width="12" textAlign="center" ph="8" fontSize="14px" children={item.content.resume} />
                    </Container>

                    <Container width="12" px="16">
                        <Button onClick={showItem} ph="16" pv="8" color="color-blue" hoverColor="color-blue-dark" fontColor="color-white" textTransform="uppercase" textAlign="center" children={<TextLocalized children="actionGetStart" />} />
                    </Container>
                </ContainerFlex>
            </Card>
        </Container>
    );
};

export default LearningUnit;