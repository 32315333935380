const fr = {
    msgAppName: "Woodcircle",

    actionGetStart: "Commencer",
    actionDetails: "Détails",
    actionLogin: "Se connecter",
    actionRegister: "S’inscrire",
    actionLogout: "Déconnexion",
    actionSend: "Envoyer",
    actionGoTest: "Faire le test",
    actionSeeCert: "Voir le certificat",
    actionNextPill: "Aller à la leçon suivante",
    actionNewThread: "Nouveau fil de discussion",
    actionBack: "Retour",
    actionComment: "Commentaire",
    actionUpdate: "Mise à jour",
    actionChangePass: "Changer de mot de passe",
    actionAddAnnouncement: "Ajouter une annonce",
    actionStartTest: "Commencer le test",
    actionSubmit: "Soumettre",
    actionClose: "Fermer",
    actionEdit: "Modifier",
    actionDelete: "Supprimer",
    actionShow: "Afficher",
    actionNext: "Suivant",
    actionDone: "Fait",
    actionOpenResource: "Ouvrir une ressource",
    actionDownloadInfo: "Télécharger le manuel d’utilisation",
    actionDownloadScript: "Télécharger le script",
    actionDownloadPresentation: "Télécharger la présentation",
    actionCourseCertificate: "Certificat de cours",
    actionRequestCode: "Code de la demande",


    msgWelcome: "Bienvenue",
    msgBackToUnit: "Retour à l'unité d'apprentissage",
    msgExploreUnits: " Explorez le cours WOODCircle",
    msgExploreUnitsText: " La double transition modifie les processus internes des entreprises du Bois et de l'Ameublement à travers deux tendances fortes qui peuvent rendre nos entreprises plus attractives pour les jeunes : la digitalisation et l'économie circulaire.  WOODCircle vise à faire face à cette transition, en se concentrant sur la création d'un nouveau profil professionnel : le menuisier circulaire et durable. Ce cours de formation en ligne permet aux apprenants d'acquérir des compétences techniques et environnementales liées à l'économie circulaire et au développement durable, leur permettant ainsi de répondre aux besoins du marché du travail. WOODCircle est le projet jumeau du précédent projet Erasmus+ <a style='color:white; cursor: pointer;' target='blank' href='https://www.woodigital.eu'>WOODigital</a>, géré par le même consortium et visant à aborder la perspective \"numérique\" de la double transition en fournissant des aptitudes et des compétences numériques pour permettre aux apprenants de devenir des TRAVAILLEURS DU BOIS 4.0 compétents.",
    msgLearningUnits: "Unités d'apprentissage",
    msgLatestPills: "Leçons recommandées",
    msgLatestPillsText: "Découvrez les leçons recommandées par nos partenaires européens.",


    msgPills: "Leçons",
    msgPartners: "Partenaires",
    msgNextLearning: "Unités d'apprentissage suivantes",


    msgExamAlert: "Après avoir suivi toutes les leçons de l'unité d'apprentissage, les élèves doivent remplir un <b>formulaire d'évaluation</b> afin d'obtenir <b>un certificat d'achèvement et un badge numérique.</b> Un <b>résultat minimum de 75%</b> est requis pour réussir l'unité d'apprentissage.",
    msgAssignment: "Affectation",
    msgForStudents: "PROJET D'AFFECTATION LU1-4",
    msgForTeachers: "ASSIGNMENT LU%d",
    msgProgressionOptions: "Options de progression",
    msgProgressionOptionsSubtitle: "Liens vers des options de progression supplémentaires en Irlande, en Espagne, en Italie, en France et en Hongrie.",
    msgCompetencesUnitTitle: "A l'issue des leçons de l'unité d'apprentissage, les étudiants devraient être capables de :",


    msgCompetencesTitle: "A l'issue de cette leçon, les élèves devraient être capables de :",
    msgTopics: "Sujets",
    msgHowApply: "Bonne pratique",
    msgCaseStudy: "Étude de cas",
    msgInterview: "Interview",
    msgResources: "Ressources d'apprentissage",
    msgResourcesSubtitle: "Liens vers des lectures complémentaires et des documents en libre accès.",


    msgStartTestSubtitle: "**Lorsque toutes les leçons de l'unité d'apprentissage ont été suivies**",


    msgWooLearningPlatform: "Plateforme d'apprentissage WOODCircle",
    msgWooLearningPlatformText: "La plateforme de formation WOODCircle est dédiée aux personnes et institutions intéressées par la promotion de l'économie circulaire et de la durabilité et bénéficiant d'une opportunité de formation de qualité.<br/><br/>La plateforme nourrit une génération de jeunes européens, étudiants, professionnels, mais aussi les centres de formation professionnelle, les RTO et les entreprises, et leur fournit les aptitudes et compétences requises par le marché de l'emploi du bois et de l'ameublement qui subit un processus de transition circulaire. Nous recommandons vivement cette formation à :",


    msgStudents: "Étudiants",
    msgEducators: "Éducateurs",
    msgCompanies: "Entreprises et associations",
    msgProfessionals: "Professionnels / Sans emploi",


    msgStudentsText: "Les futurs travailleurs du bois qui, avant d'entrer sur le marché du travail, souhaitent acquérir les compétences vertes et circulaires nécessaires et s'assurer qu'ils ont une connaissance actualisée de l'industrie du bois et de l'ameublement en Europe.",
    msgEducatorsText: "Les centres de formation professionnelle qui forment les futurs menuisiers et qui sont intéressés par un contenu de formation innovant et qualitatif.",
    msgCompaniesText: "Les entreprises du secteur du bois et de l'ameublement désireuses d'améliorer les compétences vertes et circulaires de leurs employés",
    msgProfessionalsText: "Les personnes à la recherche d'opportunités pour développer des compétences professionnelles spécifiques et/ou découvrir de nouvelles voies de carrière.",


    msgContact: "Contact",
    msgHome: "Accueil",
    msgForum: "Forum",
    msgProfile: "Profil",


    msgAnnouncements: "Annonces",
    msgAnnouncementsFinished: "Annonces terminées",


    msgThreads: "Threads",
    msgOpen: "Ouvrir",
    msgClose: "Fermer",
    msgLeaveComment: "Laisser un commentaire",


    msgTeacherProfile: "Profil de l’enseignant",
    msgUserProfile: "Profil de l’utilisateur",
    msgUserInfo: "Informations sur l’utilisateur",


    msgRegisterText: "Inscrivez-vous et découvrez le cours de formation en ligne WOODCircle.",


    msgName: "Nom et Prénom",
    msgEmail: "Email",
    msgCountry: "Pays",
    msgAge: "Age",
    msgGender: "Genre",
    msgOrganization: "Organisation",


    msgForgetPass: "Vous avez oublié votre mot de passe ?",
    msgRecoveryPassword: "Mot de passe de récupération",
    msgCode: "Code",


    msgPassword: "Mot de passe",
    msgPassInfo: "Changez votre mot de passe actuel",
    msgCurrentPass: "Mot de passe actuel",
    msgNewPassword: "Nouveau mot de passe",


    msgNewAnnouncement: "Nouvelle annonce",
    msgNewAnnouncementInfo: "Ajouter une nouvelle annonce",
    msgAnnouncesInfo: "Liste de mes annonces",


    msgMyCertificates: "Mes certificats",
    msgMyCertificatesInfo: "Liste de tous mes certificats",


    msgDatenEnd: "Fin de la date",
    msgText: "Texte",
    msgWriteText: "Ecrivez votre texte",


    msgTitle: "Titre",


    msgTerms: "Accepter nos données <a href='https://ambitcluster.org/en/privacy-policy'>privacy policy.</a>",
    msgCopyright: "Copyright&copy;2023 WOODCIRCLE. Tous droits réservés",


    msgProyectOwner: "UN PROJET DE",
    msgFooterContact: "CONTACTEZ-NOUS",
    msgFooterNews: "NOTRE NEWSLETTER",
    msgFooterLegal: "Avis juridique",
    msgFooterPrivacy: "Vie Privée",
    msgFooterCookies: "Politique en matière de cookies",
    msgFooterCondic: "Conditions générales de service",
    msgFooterCalidad: "Politique de qualité",
    msgFooterEu: "Le projet \"WOODcircle\"  est cofinancé par le programme Erasmus+ de l'Union européenne. Les points de vue et opinions exprimés n'engagent cependant que leur(s) auteur(s) et ne reflètent pas nécessairement ceux de la Commission de l'Union européenne, qui ne peut en être tenue pour responsable.",
    msgFooterGr: "Grant Agreement: 2022-1-IE01-KA220-VET-000086833",
    msgCookies: "Ce site utilise des cookies de publicité comportementale de tiers appartenant à Google, persistants pendant 2 ans, pour améliorer nos services et vous montrer des publicités liées à vos préférences en analysant vos habitudes de navigation. Si vous continuez à naviguer, nous considérons que vous acceptez son utilisation. Vous pouvez révoquer votre consentement et obtenir plus d'informations en consultant notre politique en matière de cookies.",
    msgFooterLinkedIn: 'LINKEDIN GROUP',


    msgSendSuccess: "Succès de l'opération",


    errorOblData: "Remplir tous les champs obligatoires",
    errorLogin: "Email/mot de passe incorrect",
    errorSend: "Erreur d'opération, veuillez vérifier vos informations",
    errorEmailFormat: "Erreur de format d'email",
    errorRecovery: "Erreur de mot de passe de récupération, veuillez vérifier le code fourni",
    errorTerms: "Vous devez accepter les conditions générales",


    msgNotPass: "Vous n'avez pas réussi ce test, veuillez relire la leçon et réessayer.",
    msgCongratulations: "Félicitations ! Vous avez réussi ce test. Vous trouverez votre certificat dans la section Profil.",
    /* PROFILS TYPES */
    msgStudent: "Étudiant",
    msgVetProvider: "Enseignant - VET",
    msgHightEducation: "Enseignant - Enseignement supérieur",
    msgCompany: "Entreprise",
    msgCompanyWorker: "Employé de l'entreprise",
    msgCompanyManager: "Responsable de l'entreprise",
    msgOther: "Autres",
    /* GENRE */
    msgFemale: "Femme",
    msgMale: "Homme",
    msgNonBinary: "Non-binaire",
    msgTransgender: "Transgenrer",
    msgIntersex: "Intersexe",
    msgLetme: "Laissez-moi taper…",
    msgNotSay: "Je préfère ne pas le dire",
    /* PAYS */
    msgAfghanistan: "Afghanistan",
    msgAlbania: "Albania",
    msgAlgeria: "Algeria",
    msgAmericanSamoa: "American Samoa",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguilla",
    msgAntarctica: "Antarctica",
    msgAntiguaandBarbuda: "Antigua and Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaijan",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahrain",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Belarus",
    msgBelgium: "Belgium",
    msgBelize: "Belize",
    msgBenin: "Benin",
    msgBermuda: "Bermuda",
    msgBhutan: "Bhutan",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia and Herzegovina",
    msgBotswana: "Botswana",
    msgBouvetIsland: "Bouvet Island",
    msgBrazil: "Brazil",
    msgBritishIndian: "British Indian Ocean Territory",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Cambodia",
    msgCameroon: "Cameroon",
    msgCanada: "Canada",
    msgCapeVerde: "Cape Verde",
    msgCaymanIslands: "Cayman Islands",
    msgCentralAfrican: "Central African Republic",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Christmas Island",
    msgCocosIslands: "Cocos (Keeling) Islands",
    msgColombia: "Colombia",
    msgComoros: "Comoros",
    msgCongo: "Congo",
    msgRepublicCongo: "The Democratic Republic of Congo",
    msgCookIslands: "Cook Islands",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Ivory Coast",
    msgCroatia: "Croatia",
    msgCuba: "Cuba",
    msgCyprus: "Cyprus",
    msgCzechRepublic: "Czech Republic",
    msgDenmark: "Denmark",
    msgDjibouti: "Djibouti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "Dominican Republic",
    msgEastTimor: "East Timor",
    msgEcuador: "Ecuador",
    msgEgypt: "Egypt",
    msgEngland: "England",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Equatorial Guinea",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Ethiopia",
    msgFalklandIslands: "Falkland Islands",
    msgFaroeIslands: "Faroe Islands",
    msgFijiIslands: "Fiji Islands",
    msgFinland: "Finland",
    msgFrance: "France",
    msgFrenchGuiana: "French Guiana",
    msgFrenchPolynesia: "French Polynesia",
    msgFrenchSouthern: "French Southern territories",
    msgGabon: "Gabon",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Germany",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Greece",
    msgGreenland: "Greenland",
    msgGrenada: "Grenada",
    msgGuadeloupe: "Guadeloupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernsey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haiti",
    msgHeardMcDonald: "Heard Island and McDonald Islands",
    msgHolySee: "Holy See (Vatican City State)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungary",
    msgIceland: "Iceland",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Iran",
    msgIraq: "Iraq",
    msgIreland: "Ireland",
    msgIsrael: "Israel",
    msgIsleMan: "Isle of Man",
    msgItaly: "Italy",
    msgJamaica: "Jamaica",
    msgJapan: "Japan",
    msgJersey: "Jersey",
    msgJordan: "Jordan",
    msgKazakhstan: "Kazakhstan",
    msgKenya: "Kenya",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kyrgyzstan",
    msgLaos: "Laos",
    msgLatvia: "Latvia",
    msgLebanon: "Lebanon",
    msgLesotho: "Lesotho",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Libyan Arab Jamahiriya",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lithuania",
    msgLuxembourg: "Luxembourg",
    msgMacao: "Macao",
    msgNorthMacedonia: "North Macedonia",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malaysia",
    msgMaldives: "Maldives",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Marshall Islands",
    msgMartinique: "Martinique",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauritius",
    msgMayotte: "Mayotte",
    msgMexico: "Mexico",
    msgMicronesia: "Micronesia, Federated States of",
    msgMoldova: "Moldova",
    msgMonaco: "Monaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Morocco",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Netherlands",
    msgNetherlandsAntilles: "Netherlands Antilles",
    msgNewCaledonia: "New Caledonia",
    msgNewZealand: "New Zealand",
    msgNicaragua: "Nicaragua",
    msgNiger: "Niger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Norfolk Island",
    msgNorthKorea: "North Korea",
    msgNorthernIreland: "Northern Ireland",
    msgNorthernMariana: "Northern Mariana Islands",
    msgNorway: "Norway",
    msgOman: "Oman",
    msgPakistan: "Pakistan",
    msgPalau: "Palau",
    msgPalestine: "Palestine",
    msgPanama: "Panama",
    msgPapua: "Papua New Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Peru",
    msgPhilippines: "Philippines",
    msgPitcairn: "Pitcairn",
    msgPoland: "Poland",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunion",
    msgRomania: "Romania",
    msgRussianFederation: "Russian Federation",
    msgRwanda: "Rwanda",
    msgSaintHelena: "Saint Helena",
    msgSaintKitts: "Saint Kitts and Nevis",
    msgSaintLucia: "Saint Lucia",
    msgSaintPierre: "Saint Pierre and Miquelon",
    msgSaintVincent: "Saint Vincent and the Grenadines",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Sao Tome and Principe",
    msgSaudiArabia: "Saudi Arabia",
    msgScotland: "Scotland",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leone",
    msgSingapore: "Singapore",
    msgSlovakia: "Slovakia",
    msgSlovenia: "Slovenia",
    msgSolomonIslands: "Solomon Islands",
    msgSomalia: "Somalia",
    msgSouthAfrica: "South Africa",
    msgSouthSandwich: "South Georgia and the South Sandwich Islands",
    msgSouthKorea: "South Korea",
    msgSouthSudan: "South Sudan",
    msgSpain: "Spain",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudan",
    msgSuriname: "Suriname",
    msgSvalbardJan: "Svalbard and Jan Mayen",
    msgSwaziland: "Swaziland",
    msgSweden: "Sweden",
    msgSwitzerland: "Switzerland",
    msgSyria: "Syria",
    msgTajikistan: "Tajikistan",
    msgTanzania: "Tanzania",
    msgThailand: "Thailand",
    msgTimorLeste: "Timor-Leste",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad and Tobago",
    msgTunisia: "Tunisia",
    msgTurkey: "Turkey",
    msgTurkmenistan: "Turkmenistan",
    msgTurksCaicos: "Turks and Caicos Islands",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ukraine",
    msgUnitedArabEmirates: "United Arab Emirates",
    msgUnitedKingdom: "United Kingdom",
    msgUnitedStates: "United States",
    msgUnitedStatesMinor: "United States Minor Outlying Islands",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistan",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Virgin Islands, British",
    msgVirginIslandsUS: "Virgin Islands, U.S.",
    msgWales: "Wales",
    msgWallisFutuna: "Wallis and Futuna",
    msgWesternSahara: "Western Sahara",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabwe",
}

export default fr;