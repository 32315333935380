const es = {
    msgAppName: "Woodcircle",

    actionGetStart: "Empezar",
    actionDetails: "Detalles",
    actionLogin: "Iniciar sesión",
    actionRegister: "Registrarse",
    actionLogout: "Cerrar sesión",
    actionSend: "Enviar",
    actionGoTest: "Ir al test",
    actionSeeCert: "Ver certificado",
    actionNextPill: "Ir a la siguiente lección",
    actionNewThread: "Nuevo hilo",
    actionBack: "Volver atrás",
    actionComment: "Comentar",
    actionUpdate: "Actualizar",
    actionChangePass: "Cambiar contraseña",
    actionAddAnnouncement: "Añadir anuncio",
    actionStartTest: "Iniciar test",
    actionSubmit: "Enviar",
    actionClose: "Cerrar",
    actionEdit: "Editar",
    actionDelete: "Borrar",
    actionShow: "Mostrar",
    actionNext: "Siguiente",
    actionDone: "Hecho",
    actionOpenResource: "Abrir recurso",
    actionDownloadInfo: "Descargar el manual de usuario",
    actionDownloadScript: "Descargar el guión",
    actionDownloadPresentation: "Descargar la presentación",
    actionCourseCertificate: "Certificado del curso",
    actionRequestCode: "Código de solicitud",

    msgWelcome: "Bienvenido/a",
    msgBackToUnit: "Volver a la unidad de aprendizaje",
    msgExploreUnits: "Explora el curso de formación online WOODCircle",
    msgExploreUnitsText: "La doble transición está cambiando los procesos internos de las empresas de la Madera y el Mueble a través de dos fuertes tendencias que pueden hacer nuestras empresas más atractivas para los jóvenes: la digitalización y la economía circular. WOODCircle pretende hacer frente a esta doble transición, centrándose en la creación de un nuevo perfil profesional: el Trabajador/a de la Madera y el Mueble Circular y Sostenible. Este curso de formación online proporciona a los alumnos/as habilidades y competencias tanto técnicas como medioambientales relacionadas con la Economía Circular y la Sostenibilidad, permitiéndoles responder a las necesidades del mercado laboral. WOODCircle es un proyecto hermano del anterior proyecto Erasmus+ <a style='color:white; cursor: pointer;' target='blank' href='https://www.woodigital.eu'>WOODigital</a>, gestionado por el mismo consorcio y cuyo objetivo fue abordar la perspectiva \"digital\" de la doble transición proporcionando habilidades y competencias digitales que permitan a los alumnos/as convertirse en WOODWORKERS 4.0 competentes",
    msgLearningUnits: "Unidades de aprendizaje",
    msgLatestPills: "Lecciones recomendadas",
    msgLatestPillsText: "Descubre las lecciones recomendadas por nuestros socios europeos. ",

    msgPills: "Lecciones",
    msgPartners: "Socios",
    msgNextLearning: "Siguientes unidades de aprendizaje",

    msgExamAlert: "Al completar todas las lecciones de la unidad de aprendizaje, los estudiantes deben completar una <b>autoevaluación de opción múltiple</b> para obtener un <b>certificado de finalización y una acreditación digital.</b> Se requiere un <b>resultado mínimo del 75%</b> para aprobar la Unidad de Aprendizaje.",
    msgAssignment: "Ejercicio",
    msgForStudents: "PROYECTO UA1-4",
    msgForTeachers: "EJERCICIO UA%d",
    msgProgressionOptions: "Opciones de progresión",
    msgProgressionOptionsSubtitle: "Enlaces a algunas opciones de progresión adicionales en Irlanda, España, Italia, Francia y Hungría",
    msgCompetencesUnitTitle: "Al finalizar las lecciones de la unidad de aprendizaje, los alumnos/as deberán ser capaces de:",

    msgCompetencesTitle: "Al finalizar esta lección, los alumnos/as deberán ser capaces de:",
    msgTopics: "Temas",
    msgHowApply: "Buena práctica",
    msgCaseStudy: "Caso de estudio",
    msgInterview: "Entrevista",
    msgResources: "Recursos de aprendizaje",
    msgResourcesSubtitle: "Enlaces a lecturas adicionales y materiales de libre acceso",

    msgStartTestSubtitle: "**Al completar todas las lecciones de la unidad de aprendizaje**",

    msgWooLearningPlatform: "Plataforma de aprendizaje WOODCircle",
    msgWooLearningPlatformText: "La plataforma de formación WOODCircle está dedicada a personas e instituciones interesadas en fomentar la economía circular y la sostenibilidad y beneficiarse de una oportunidad de formación de calidad.<br/><br/>La plataforma nutre a una generación de jóvenes europeos, estudiantes, profesionales, pero también a centros de FP, RTOs y empresas, y les proporciona las habilidades y competencias requeridas por el mercado laboral del sector de la madera y el mueble en materia de transición circular. Recomendamos encarecidamente esta formación a:",

    msgStudents: "Estudiantes",
    msgEducators: "Educadores/as",
    msgCompanies: "Empresas y Asociaciones",
    msgProfessionals: "Profesionales / Desempleados/as",

    msgStudentsText: "Futuros trabajadores/as de la madera que antes de entrar en el mercado laboral deseen adquirir las habilidades verdes y circulares necesarias y asegurarse de que tienen un conocimiento actualizado de la industria de la madera y el mueble en Europa",
    msgEducatorsText: "Centros de FP y formación que eduquen a futuros trabajadores/as de la madera y estén interesados en contenidos formativos innovadores y de calidad",
    msgCompaniesText: "Empresas de la madera y el mueble dispuestas a mejorar las competencias verdes y circulares de sus empleados/as",
    msgProfessionalsText: "Aquellos/as que buscan oportunidades para desarrollar habilidades profesionales específicas y/o descubrir nuevas trayectorias profesionales",

    msgContact: "Contacto",
    msgHome: "Inicio",
    msgForum: "Foro",
    msgProfile: "Perfil",

    msgAnnouncements: "Anuncios",
    msgAnnouncementsFinished: "Anuncios finalizados",

    msgThreads: "Temas",
    msgOpen: "Abrir",
    msgClose: "Cerrar",
    msgLeaveComment: "Dejar un comentario",

    msgTeacherProfile: "Perfil del profesor/a",
    msgUserProfile: "Perfil de usuario/a",
    msgUserInfo: "Información del usuario/a",

    msgRegisterText: "Regístrate y explora el curso de formación online WOODCircle",

    msgName: "Nombre y apellidos",
    msgEmail: "Correo electrónico",
    msgCountry: "País",
    msgAge: "Edad",
    msgGender: "Sexo",
    msgOrganization: "Organización",

    msgForgetPass: "¿Olvidaste tu contraseña?",
    msgRecoveryPassword: "Contraseña de recuperación",
    msgCode: "Código",

    msgPassword: "Contraseña",
    msgPassInfo: "Cambia tu contraseña actual",
    msgCurrentPass: "Contraseña actual",
    msgNewPassword: "Nueva contraseña",

    msgNewAnnouncement: "Nuevo anuncio",
    msgNewAnnouncementInfo: "Añadir un nuevo anuncio",
    msgAnnouncesInfo: "Lista de mis anuncios",

    msgMyCertificates: "Mis certificados",
    msgMyCertificatesInfo: "Lista de todos mis certificados",

    msgDatenEnd: "Fin de fecha",
    msgText: "Texto",
    msgWriteText: "Escribe tu texto",

    msgTitle: "Título",

    msgTerms: "Acepta nuestra <a href='https://ambitcluster.org/en/privacy-policy'>política de privacidad de datos.</a>",
    msgCopyright: "Copyright&copy;2023 WOODcircle. Todos los derechos reservados",

    msgProyectOwner: "UN PROYECTO DE",
    msgFooterContact: "CONTÁCTANOS",
    msgFooterNews: "NUESTRO BOLETÍN DE NOTICIAS",
    msgFooterLegal: "Aviso legal",
    msgFooterPrivacy: "Privacidad",
    msgFooterCookies: "Política de cookies",
    msgFooterCondic: "Condiciones generales del servicio",
    msgFooterCalidad: "Política de calidad",
    msgFooterEu: "El proyecto \"WOODcircle\" está cofinanciado por el programa Erasmus+ de la Unión Europea. No obstante, las opiniones expresadas son responsabilidad exclusiva del autor/a o autores y no reflejan necesariamente las de la Unión Europea, quién no se hace responsable de las mismas",
    msgFooterGr: "Convenio de subvención: 2022-1-IE01-KA220-VET-000086833",
    msgCookies: "Esta Web utiliza cookies de terceros de publicidad comportamental propiedad de Google, persistentes durante 2 años, para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. Si continúa navegando consideramos que está de acuerdo con su uso. Podrá revocar el consentimiento y obtener más información consultando nuestra Política de cookies.",
    msgFooterLinkedIn: "GRUPO LINKEDIN",

    msgSendSuccess: "Éxito de la operación",

    errorOblData: "Rellenar todos los campos obligatorios",
    errorLogin: "Correo electrónico/contraseña incorrectos",
    errorSend: "Error de operación, por favor revise su información",
    errorEmailFormat: "Error de formato de correo electrónico",
    errorRecovery: "Error de contraseña de recuperación, por favor compruebe el código proporcionado",
    errorTerms: "Debe aceptar los términos y condiciones",

    msgNotPass: "No has superado esta prueba, vuelva a leer la lección e inténtalo de nuevo",
    msgCongratulations: "¡Enhorabuena! Has superado esta prueba. Puedes encontrar tu certificado en la sección Perfil",

    msgStudent: "Alumno/a",
    msgVetProvider: "Profesor/a - FP",
    msgHightEducation: "Profesor/a - Enseñanza Superior",
    msgCompany: "Empresa",
    msgCompanyWorker: "Trabajador/a de empresa",
    msgCompanyManager: "Director/a de empresa",
    msgOther: "Otro",

    msgFemale: "Mujer",
    msgMale: "Hombre",
    msgNonBinary: "No binario",
    msgTransgender: "Transgénero",
    msgIntersex: "Intersexual",
    msgLetme: "Déjame escribir...",
    msgNotSay: "Prefiero no decirlo",

    msgAfghanistan: "Afganistán",
    msgAlbania: "Albania",
    msgAlgeria: "Argelia",
    msgAmericanSamoa: "Samoa Americana",
    msgAndorra: "Andorra",
    msgAngola: "Angola",
    msgAnguilla: "Anguila",
    msgAntarctica: "Antártida",
    msgAntiguaandBarbuda: "Antigua y Barbuda",
    msgArgentina: "Argentina",
    msgArmenia: "Armenia",
    msgAruba: "Aruba",
    msgAustralia: "Australia",
    msgAustria: "Austria",
    msgAzerbaijan: "Azerbaiyán",
    msgBahamas: "Bahamas",
    msgBahrain: "Bahréin",
    msgBangladesh: "Bangladesh",
    msgBarbados: "Barbados",
    msgBelarus: "Bielorrusia",
    msgBelgium: "Bélgica",
    msgBelize: "Belice",
    msgBenin: "Benín",
    msgBermuda: "Bermudas",
    msgBhutan: "Bután",
    msgBolivia: "Bolivia",
    msgBosniaHerzegovina: "Bosnia y Herzegovina",
    msgBotswana: "Botsuana",
    msgBouvetIsland: "Isla Bouvet",
    msgBrazil: "Brasil",
    msgBritishIndian: "Territorio Británico del Océano Índico",
    msgBrunei: "Brunei",
    msgBulgaria: "Bulgaria",
    msgBurkinaFaso: "Burkina Faso",
    msgBurundi: "Burundi",
    msgCambodia: "Camboya",
    msgCameroon: "Camerún",
    msgCanada: "Canadá",
    msgCapeVerde: "Cabo Verde",
    msgCaymanIslands: "Islas Caimán",
    msgCentralAfrican: "República Centroafricana",
    msgChad: "Chad",
    msgChile: "Chile",
    msgChina: "China",
    msgChristmasIsland: "Isla de Navidad",
    msgCocosIslands: "Islas Cocos (Keeling)",
    msgColombia: "Colombia",
    msgComoros: "Comoras",
    msgCongo: "Congo",
    msgRepublicCongo: "República Democrática del Congo",
    msgCookIslands: "Islas Cook",
    msgCostaRica: "Costa Rica",
    msgIvoryCoast: "Costa de Marfil",
    msgCroatia: "Croacia",
    msgCuba: "Cuba",
    msgCyprus: "Chipre",
    msgCzechRepublic: "República Checa",
    msgDenmark: "Dinamarca",
    msgDjibouti: "Yibuti",
    msgDominica: "Dominica",
    msgDominicanRepublic: "República Dominicana",
    msgEastTimor: "Timor Oriental",
    msgEcuador: "Ecuador",
    msgEgypt: "Egipto",
    msgEngland: "Inglaterra",
    msgElSalvador: "El Salvador",
    msgEquatorialGuinea: "Guinea Ecuatorial",
    msgEritrea: "Eritrea",
    msgEstonia: "Estonia",
    msgEthiopia: "Etiopía",
    msgFalklandIslands: "Islas Malvinas",
    msgFaroeIslands: "Islas Feroe",
    msgFijiIslands: "Islas Fiyi",
    msgFinland: "Finlandia",
    msgFrance: "Francia",
    msgFrenchGuiana: "Guayana Francesa",
    msgFrenchPolynesia: "Polinesia Francesa",
    msgFrenchSouthern: "Territorios Australes Franceses",
    msgGabon: "Gabón",
    msgGambia: "Gambia",
    msgGeorgia: "Georgia",
    msgGermany: "Alemania",
    msgGhana: "Ghana",
    msgGibraltar: "Gibraltar",
    msgGreece: "Grecia",
    msgGreenland: "Groenlandia",
    msgGrenada: "Granada",
    msgGuadeloupe: "Guadalupe",
    msgGuam: "Guam",
    msgGuatemala: "Guatemala",
    msgGuernsey: "Guernesey",
    msgGuinea: "Guinea",
    msgGuineaBissau: "Guinea-Bissau",
    msgGuyana: "Guyana",
    msgHaiti: "Haití",
    msgHeardMcDonald: "Isla Heard e Islas McDonald",
    msgHolySee: "Santa Sede (Estado de la Ciudad del Vaticano)",
    msgHonduras: "Honduras",
    msgHongKong: "Hong Kong",
    msgHungary: "Hungría",
    msgIceland: "Islandia",
    msgIndia: "India",
    msgIndonesia: "Indonesia",
    msgIran: "Irán",
    msgIraq: "Iraq",
    msgIreland: "Irlanda",
    msgIsrael: "Israel",
    msgIsleMan: "Isla de Man",
    msgItaly: "Italia",
    msgJamaica: "Jamaica",
    msgJapan: "Japón",
    msgJersey: "Jersey",
    msgJordan: "Jordania",
    msgKazakhstan: "Kazajstán",
    msgKenya: "Kenia",
    msgKiribati: "Kiribati",
    msgKuwait: "Kuwait",
    msgKyrgyzstan: "Kirguistán",
    msgLaos: "Laos",
    msgLatvia: "Letonia",
    msgLebanon: "Líbano",
    msgLesotho: "Lesoto",
    msgLiberia: "Liberia",
    msgLibyanJamahiriya: "Jamahiriya Árabe Libia",
    msgLiechtenstein: "Liechtenstein",
    msgLithuania: "Lituania",
    msgLuxembourg: "Luxemburgo",
    msgMacao: "Macao",
    msgNorthMacedonia: "Macedonia del Norte",
    msgMadagascar: "Madagascar",
    msgMalawi: "Malawi",
    msgMalaysia: "Malasia",
    msgMaldives: "Maldivas",
    msgMali: "Mali",
    msgMalta: "Malta",
    msgMarshallIslands: "Islas Marshall",
    msgMartinique: "Martinica",
    msgMauritania: "Mauritania",
    msgMauritius: "Mauricio",
    msgMayotte: "Mayotte",
    msgMexico: "México",
    msgMicronesia: "Micronesia, Estados Federados de",
    msgMoldova: "Moldavia",
    msgMonaco: "Mónaco",
    msgMongolia: "Mongolia",
    msgMontserrat: "Montserrat",
    msgMontenegro: "Montenegro",
    msgMorocco: "Marruecos",
    msgMozambique: "Mozambique",
    msgMyanmar: "Myanmar",
    msgNamibia: "Namibia",
    msgNauru: "Nauru",
    msgNepal: "Nepal",
    msgNetherlands: "Países Bajos",
    msgNetherlandsAntilles: "Antillas Neerlandesas",
    msgNewCaledonia: "Nueva Caledonia",
    msgNewZealand: "Nueva Zelanda",
    msgNicaragua: "Nicaragua",
    msgNiger: "Níger",
    msgNigeria: "Nigeria",
    msgNiue: "Niue",
    msgNorfolkIsland: "Isla Norfolk",
    msgNorthKorea: "Corea del Norte",
    msgNorthernIreland: "Irlanda del Norte",
    msgNorthernMariana: "Islas Marianas del Norte",
    msgNorway: "Noruega",
    msgOman: "Omán",
    msgPakistan: "Pakistán",
    msgPalau: "Palaos",
    msgPalestine: "Palestina",
    msgPanama: "Panamá",
    msgPapua: "Papúa Nueva Guinea",
    msgParaguay: "Paraguay",
    msgPeru: "Perú",
    msgPhilippines: "Filipinas",
    msgPitcairn: "Pitcairn",
    msgPoland: "Polonia",
    msgPortugal: "Portugal",
    msgPuertoRico: "Puerto Rico",
    msgQatar: "Qatar",
    msgReunion: "Reunión",
    msgRomania: "Rumanía",
    msgRussianFederation: "Federación de Rusia",
    msgRwanda: "Ruanda",
    msgSaintHelena: "Santa Elena",
    msgSaintKitts: "San Cristóbal y Nieves",
    msgSaintLucia: "Santa Lucía",
    msgSaintPierre: "San Pedro y Miquelón",
    msgSaintVincent: "San Vicente y las Granadinas",
    msgSamoa: "Samoa",
    msgSanMarino: "San Marino",
    msgSaoTome: "Santo Tomé y Príncipe",
    msgSaudiArabia: "Arabia Saudí",
    msgScotland: "Escocia",
    msgSenegal: "Senegal",
    msgSerbia: "Serbia",
    msgSeychelles: "Seychelles",
    msgSierraLeone: "Sierra Leona",
    msgSingapore: "Singapur",
    msgSlovakia: "Eslovaquia",
    msgSlovenia: "Eslovenia",
    msgSolomonIslands: "Islas Salomón",
    msgSomalia: "Somalia",
    msgSouthAfrica: "Sudáfrica",
    msgSouthSandwich: "Georgia del Sur y las Islas Sandwich del Sur",
    msgSouthKorea: "Corea del Sur",
    msgSouthSudan: "Sudán del Sur",
    msgSpain: "España",
    msgSriLanka: "SriLanka",
    msgSudan: "Sudán",
    msgSuriname: "Surinam",
    msgSvalbardJan: "Svalbard y Jan Mayen",
    msgSwaziland: "Suazilandia",
    msgSweden: "Suecia",
    msgSwitzerland: "Suiza",
    msgSyria: "Siria",
    msgTajikistan: "Tayikistán",
    msgTanzania: "Tanzania",
    msgThailand: "Tailandia",
    msgTimorLeste: "Timor Oriental",
    msgTogo: "Togo",
    msgTokelau: "Tokelau",
    msgTonga: "Tonga",
    msgTrinidadTobago: "Trinidad y Tobago",
    msgTunisia: "Túnez",
    msgTurkey: "Turquía",
    msgTurkmenistan: "Turkmenistán",
    msgTurksCaicos: "Islas Turcas y Caicos",
    msgTuvalu: "Tuvalu",
    msgUganda: "Uganda",
    msgUkraine: "Ucrania",
    msgUnitedArabEmirates: "Emiratos Árabes Unidos",
    msgUnitedKingdom: "Reino Unido",
    msgUnitedStates: "Estados Unidos",
    msgUnitedStatesMinor: "Islas Periféricas Menores de los Estados Unidos",
    msgUruguay: "Uruguay",
    msgUzbekistan: "Uzbekistán",
    msgVanuatu: "Vanuatu",
    msgVenezuela: "Venezuela",
    msgVietnam: "Vietnam",
    msgVirginIslandsBritish: "Islas Vírgenes, Británicas",
    msgVirginIslandsUS: "Islas Vírgenes, EE.UU.",
    msgWales: "Gales",
    msgWallisFutuna: "Wallis y Futuna",
    msgWesternSahara: "Sáhara Occidental",
    msgYemen: "Yemen",
    msgZambia: "Zambia",
    msgZimbabwe: "Zimbabue",
}

export default es;